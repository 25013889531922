import React from 'react';
import { Link } from 'react-router-dom';
import * as Routes from 'app/constants/Routes';
import { Formik, Form, Field } from 'formik';
import { Dropdown } from 'app/components/common/formik/Dropdown';
import Button from 'app/components/common/formik/Button';
import FieldError from 'app/components/common/formik/FieldError';
import { Checkbox } from 'app/components/common/formik/Checkbox';
import checkboxCheckedImage from 'images/CheckedBoxDefault.svg';
import checkboxUncheckedImage from 'images/UncheckedBoxDefault.svg';
import { RegistrationSchemaStep2 } from 'app/helpers/validators';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Button as MaximusButton, Card, Heading } from 'mui';

const msoTermsOfUseLink = '/terms-of-use';
const msoPrivacyPolicyLink = '/privacy-policy';
const pcInformedConsentLink = '/telehealth-informed-consent';
const pcPrivacyPracticesLink = '/notice-privacy-practices';

const Step1 = ({ initialValues, onSubmit }) => {
  const newVersion = useRedesign();

  return (
    <Formik
      initialValues={{
        ...initialValues,
        agree: initialValues.pc_opt_in && initialValues.mso_opt_in,
      }}
      onSubmit={({ agree, ...values }) =>
        onSubmit({
          ...values,
          pc_opt_in: agree,
          mso_opt_in: agree,
        })
      }
      validationSchema={RegistrationSchemaStep2}
    >
      {({ isSubmitting }) =>
        newVersion ? (
          <Form>
            <Card>
              <Card.Body>
                <div>
                  <Heading headingLevel="h3" className="mb-2">
                    Let’s start with the basics.
                  </Heading>
                  <p>
                    In order to make sure we are able to treat you, we need to know your age and location (where labs
                    and medication will be shipped, if qualified).
                  </p>
                </div>
                <Dropdown
                  id="state"
                  name="state"
                  emptyValue="Select Your State"
                  position="bottom"
                  className="registration-flow__dropdown"
                />
                <Checkbox id="over_18" name="over_18" label="I am at least 18 years old." labelClassName="pt-[2px]" />
                <Checkbox
                  id="agree"
                  name="agree"
                  label={
                    <>
                      By clicking this box, I acknowledge that I have read, understood, and agree to the{' '}
                      <a rel="noreferrer" href={msoTermsOfUseLink} target="_blank">
                        Terms of Use
                      </a>
                      ,{' '}
                      <a rel="noreferrer" href={msoPrivacyPolicyLink} target="_blank">
                        Privacy Policy
                      </a>
                      ,{' '}
                      <a rel="noreferrer" href={pcInformedConsentLink} target="_blank">
                        Telehealth Informed Consent
                      </a>
                      , and acknowledge the{' '}
                      <a rel="noreferrer" href={pcPrivacyPracticesLink} target="_blank">
                        Notice of Privacy Practices
                      </a>
                    </>
                  }
                />
                <MaximusButton
                  disabled={isSubmitting}
                  type="submit"
                  testid="registration-step-2-submit"
                  className="button-next"
                >
                  Next
                </MaximusButton>
                <p>
                  Already have an account? <Link to={Routes.Login}>Sign In</Link>
                </p>
              </Card.Body>
            </Card>
          </Form>
        ) : (
          <Form className="registration-flow registration-flow_step-0">
            <h2 className="title title_image">
              <span>Let’s start with the basics.</span>
            </h2>
            <p className="description">
              In order to make sure we are able to treat you, we need to know your age and location (where labs and
              medication will be shipped, if qualified).
            </p>

            <div className="inputs">
              <div className="inputs__item">
                <Dropdown
                  id="state"
                  name="state"
                  emptyValue="Select Your State"
                  showLabel={false}
                  position="bottom"
                  className="registration-flow__dropdown"
                />
              </div>
            </div>

            <div className="rules">
              <div className="rules__item">
                <Checkbox
                  id="over_18"
                  name="over_18"
                  label="I am at least 18 years old."
                  checkedImage={checkboxCheckedImage}
                  uncheckedImage={checkboxUncheckedImage}
                />
                <Field name="over_18" component={FieldError} />
              </div>

              <div className="rules__item">
                <Checkbox
                  id="agree"
                  name="agree"
                  label={
                    <>
                      By clicking this box, I acknowledge that I have read, understood, and agree to the{' '}
                      <a rel="noreferrer" href={msoTermsOfUseLink} target="_blank">
                        Terms of Use
                      </a>
                      ,{' '}
                      <a rel="noreferrer" href={msoPrivacyPolicyLink} target="_blank">
                        Privacy Policy
                      </a>
                      ,{' '}
                      <a rel="noreferrer" href={pcInformedConsentLink} target="_blank">
                        Telehealth Informed Consent
                      </a>
                      , and acknowledge the{' '}
                      <a rel="noreferrer" href={pcPrivacyPracticesLink} target="_blank">
                        Notice of Privacy Practices
                      </a>
                    </>
                  }
                  checkedImage={checkboxCheckedImage}
                  uncheckedImage={checkboxUncheckedImage}
                />
                <Field name="agree" component={FieldError} />
              </div>
            </div>

            <Button
              disabled={isSubmitting}
              style="primary"
              type="submit"
              data-testid="registration-step-2-submit"
              text="Next"
              className="button-next"
            />

            <div className="links">
              <div className="links__item">
                Already have an account? <Link to={Routes.Login}>Sign In</Link>
              </div>
            </div>
          </Form>
        )
      }
    </Formik>
  );
};

export default Step1;
