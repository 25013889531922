import React from 'react';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import Divider from '@setproduct-ui/core/Divider';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';
import { Form, Formik } from 'formik';
import InputRow from '../Checkout/InputRow';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import * as actions from 'app/actions/customer';
import { useAppDispatch } from 'app/helpers/hooks';

const validationSchema = (nextBillingCycle) =>
  Yup.object({
    resume_at: Yup.date()
      .required('Resume date is required')
      .min(new Date(nextBillingCycle), `Resume subscription date must be later than your next billing date`),
  });

const dateformat = 'MMMM D, YYYY';

type Props = {
  setPauseSubscriptionModal: (e) => void;
  nextBillingCycle: string;
  product: string;
  subProduct: string;
  resumeAt: string | null;
  pausedAt: string | null;
  paused: boolean | null;
};

const PauseSubscription = ({
  setPauseSubscriptionModal,
  nextBillingCycle,
  product,
  subProduct,
  resumeAt,
  pausedAt,
  paused,
}: Props) => {
  const dispatch = useAppDispatch();

  const pauseSub = () => {
    return (
      <div>
        <ProductHeader productName={product} subProductName={subProduct} showTitle={false} cardStyle={false} />

        <h5 className="subtitle mt24">Pause Subscription</h5>

        <p>
          <span>
            Your subscription will pause at the end of your billing cycle,{' '}
            <strong className="bold">{dayjs(nextBillingCycle).subtract(1, 'day').format(dateformat)}</strong>, and
            resume on the date you select below.
          </span>
          <br />
          <br />
          <span>You will not be charged again until your subscription resumes.</span>
          <br />
          <br />
          <span>You will receive a reminder two days before your subscription resumes.</span>
          <br />
          <br />
          <span>
            Once your subscription resumes, you will receive a notification that your refill questionnaire is ready.
            This is how you will request your next order.
          </span>
          <br />
          <br />
          <span>
            <strong className="bold">Recommended:</strong> Set your subscription to resume 7 days ahead of the date you
            want to receive your next order to allow time for fulfillment.
          </span>
        </p>
      </div>
    );
  };

  const changePause = () => {
    return (
      <div>
        <h5 className="subtitle">Subscription is Paused</h5>
        <p>
          Your subscription is scheduled to resume on{' '}
          <strong className="bold">{dayjs(resumeAt).format(dateformat)}</strong>. You can change the resume date below
          or you can resume your subscription immediately if you’re ready now.
        </p>
        <Divider className="mb24 mt24" />
      </div>
    );
  };

  const pauseSubcription = (params) => {
    setPauseSubscriptionModal(false);

    const action = actions.apiRequestUserCommand({
      cmdType: 'pause_subscription',
      params,
      context: {},
    });
    dispatch(action);
  };

  return (
    <div style={{ margin: '0 25%' }}>
      <PaymentCard className="product-selection mt24">
        {resumeAt ? changePause() : pauseSub()}

        {resumeAt && <h5 className="subtitle mt24">Change Resume Date</h5>}

        <div className="mt24">
          <Formik
            initialValues={{
              resume_at: resumeAt,
            }}
            validationSchema={validationSchema(pausedAt || nextBillingCycle)}
            onSubmit={(values) => {
              const params = { resume_at: values.resume_at, product_name: product };

              pauseSubcription(params);
            }}
          >
            {({ handleSubmit, isValid }) => (
              <Form className="mb20">
                <InputRow
                  className="mb20"
                  label="Resume Subscription Date"
                  name="resume_at"
                  type="date"
                  disabledDays={{ before: new Date() }}
                />
                <PrimaryButton
                  className="full"
                  onClick={() => handleSubmit()}
                  text={`CONFIRM ${resumeAt ? 'CHANGE' : 'PAUSE'}`}
                  disabled={!isValid}
                />
                <Divider className="mt24" />
                {paused && (
                  <PrimaryButton
                    className="full mt24"
                    onClick={() => {
                      const userConfirmed = window.confirm('Are you sure you want to resume your subscription now?');
                      if (userConfirmed) {
                        pauseSubcription({ resume_at: new Date(), product_name: product });
                      }
                    }}
                    text={'RESUME NOW'}
                  />
                )}
                <SecondaryButton
                  className="full mt24"
                  onClick={() => setPauseSubscriptionModal(false)}
                  text={`CANCEL ${resumeAt ? 'CHANGE' : 'PAUSE'}`}
                />
              </Form>
            )}
          </Formik>
        </div>
      </PaymentCard>
    </div>
  );
};

export default PauseSubscription;
