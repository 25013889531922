import React, { useState } from 'react';

import { P } from 'app/components/common/Typography';
import { useFormikContext } from 'formik';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import { AvailableProducts, KingV2SubProducts } from 'app/constants/Products';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import Divider from '@setproduct-ui/core/Divider';
import { KingSubProductsConfig } from 'app/components/customer/steps/Payment/king/KingSubProductsConfig';
import SlidersIcon from 'images/icons/sliders.svg';
import IngredientIcon from 'images/icons/ingredient.svg';
import FreeshippingIcon from 'images/icons/fast-free-shipping-2.svg';
import AtHomeIcon from 'images/icons/at-home-monitoring-2.svg';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import { EnclomipheneInfo } from 'app/components/customer/steps/Payment/king/EnclomipheneInfo';
import { HcgInfo } from 'app/components/customer/steps/Payment/king//HcgInfo';
import { TopicalTestosteroneInfo } from 'app/components/customer/steps/Payment/king/TopicalTestosteroneInfo';
import { OralTestosteroneInfo } from 'app/components/customer/steps/Payment/king/OralTestosteroneInfo';
import { InjectableTestosteroneInfo } from 'app/components/customer/steps/Payment/king//InjectableTestosteroneInfo';
import { PrimaryButton } from 'app/components/common/Button';
import { KING_MONTHLY_PRICE } from 'app/helpers/productSpecificPriceCalculators/kingPriceCalculator';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Accordion, Badge, Button, Card, IconList } from 'mui';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import subProductNames from 'app/utils/subProductNames';
import { BodySafetyInfo } from 'app/components/customer/steps/Payment/king/BodySafetyInfo';

type Props = {
  handleSubmit: () => void;
  onExploreOtherProtocols: () => void;
};

const KingSubProductScreen = ({ handleSubmit, onExploreOtherProtocols }: Props) => {
  const newVersion = useRedesign();
  const [openModalSideEffects, setOpenModalSideEffects] = useState(false);
  const [openModalSafetyInfo, setOpenModalSafetyInfo] = useState(false);
  const { values, setFieldValue } = useFormikContext<{
    selected_king_v2_product: KingV2SubProducts;
  }>();

  const subProduct = KingSubProductsConfig[values.selected_king_v2_product];

  const handleExploreOtherProtocols = () => {
    if (onExploreOtherProtocols) {
      onExploreOtherProtocols();
    }
    setFieldValue('selected_king_v2_product', false);
  };

  const renderFooter = () => (
    <div className="fixed-footer">
      <P>Plans Starting at ${KING_MONTHLY_PRICE[values.selected_king_v2_product][12] / 100}/mo</P>

      <PrimaryButton
        className="mt24 mb24"
        text="Continue"
        type="button"
        onClick={handleSubmit}
        disabled={false}
        data-testid="submit-product-selection"
      />

      <P>
        <a onClick={() => handleExploreOtherProtocols()} className="link">
          Explore Other Protocols
        </a>
      </P>
    </div>
  );

  const getProductInfo = (productName) => {
    switch (productName) {
      case 'topical_trt':
        return <TopicalTestosteroneInfo />;
      case 'oral_trt':
        return <OralTestosteroneInfo />;
      case 'injectable_trt':
        return <InjectableTestosteroneInfo />;
      case 'enclomiphene':
        return <EnclomipheneInfo />;
      case 'enclomiphene_topical_trt':
        return (
          <>
            <TopicalTestosteroneInfo />
            <EnclomipheneInfo />
          </>
        );
      case 'enclomiphene_oral_trt':
        return (
          <>
            <OralTestosteroneInfo />
            <EnclomipheneInfo />
          </>
        );
      case 'enclomiphene_injectable_trt':
        return (
          <>
            <InjectableTestosteroneInfo />
            <EnclomipheneInfo />
          </>
        );
      case 'injectable_trt_hcg':
        return (
          <>
            <InjectableTestosteroneInfo />
            <HcgInfo />
          </>
        );
        break;
      default:
        return null;
    }
  };

  const renderNewFooter = () => (
    <div className="fixed-footer">
      <div className="m-auto max-w-[26rem]">
        <p>Plans Starting at ${KING_MONTHLY_PRICE[values.selected_king_v2_product][12] / 100}/mo</p>

        <Button
          className="mt-4 mb-4"
          type="button"
          onClick={handleSubmit}
          disabled={false}
          testid="submit-product-selection"
        >
          Continue with this Protocol
        </Button>

        <p>
          <a onClick={() => setFieldValue('selected_king_v2_product', false)} className="no-underline">
            Explore Other Treatments
          </a>
        </p>
      </div>
    </div>
  );

  return newVersion ? (
    <>
      <Card>
        <Card.Body>
          <figure className="h-[180px] mb-4">
            <img
              className="h-full w-auto"
              src={ImagesMapping[AvailableProducts.King][values.selected_king_v2_product]}
              alt="king v2"
            />
          </figure>
          <div className="text-center">
            <Badge variant={AvailableProducts.King} className="mb-2">
              {protocolNames[AvailableProducts.King]}
            </Badge>
            <h4>{subProductNames[AvailableProducts.King][values.selected_king_v2_product]}</h4>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h4>Treatment Details</h4>
          <p>{subProduct.recommended_for}</p>
          <Divider />
          <h4>Active Ingredients</h4>
          {subProduct.active_ingredients.map((ingredient) => (
            <>
              <IconList
                key={ingredient.name}
                items={[
                  {
                    title: ingredient.name,
                    text: ingredient.description,
                    icon: () => <img src={IngredientIcon} alt="ingredient" />,
                  },
                ]}
              />
              <hr />
            </>
          ))}
          <h4>What to Expect</h4>
          <IconList
            items={[
              {
                title: 'Check Your Levels',
                text: 'Receive your At-Home Lab Kit to check your testosterone levels quickly.',
                icon: () => <img src={AtHomeIcon} alt="levels" />,
              },
              {
                title: 'Get Your Prescription',
                text: 'Once approved, your prescription is shipped straight to your door.',
                icon: () => <img src={FreeshippingIcon} alt="free shipping" />,
              },
              {
                title: 'Continuous Optimization',
                text:
                  'After your first month, we send another lab kit to ensure progress. Your doctor will adjust your dosage\n' +
                  ' for optimal results.',
                icon: () => <img src={SlidersIcon} alt="at home monitoring" />,
              },
            ]}
          />
          <div className="-mb-8 md:-mb-12 lg:-mb-8">
            <hr />
            <Accordion
              items={[
                {
                  title: 'Side Effects',
                  content: subProduct.side_effects,
                },
                {
                  title: 'Important safety information',
                  content: <BodySafetyInfo />,
                },
              ]}
            />
          </div>
        </Card.Body>
      </Card>
      {renderNewFooter()}
    </>
  ) : (
    <div style={{ marginBottom: '200px' }}>
      <PaymentCard className="product-selection">
        <ProductHeader
          productName={AvailableProducts.King}
          subProductName={values.selected_king_v2_product}
          showTitle={true}
          cardStyle={false}
        />

        <Divider className="mt16 mb16" />

        <h5 className="subtitle">Recommended for</h5>
        <p>{subProduct.recommended_for}</p>

        <Divider className="mt16 mb16" />

        <h5 className="subtitle">Active Ingredients</h5>
        <p className="mh8">
          {subProduct.active_ingredients.map((ingredient) => (
            <p className="mb16" key={ingredient.name}>
              <strong className="bold">{ingredient.name}</strong>
              <br />
              {ingredient.description}
            </p>
          ))}
        </p>

        <Divider className="mb16" />

        <h5 className="subtitle">What to Expect</h5>

        <div className="what-to-expect">
          <div className="mb16">
            <img src={AtHomeIcon} />
            <p>
              <strong className="bold">Check Your Levels</strong>
              <br />
              Receive your At-Home Lab Kit to check your testosterone levels quickly.
            </p>
          </div>
          <div className="mb16">
            <img src={FreeshippingIcon} />
            <p>
              <strong className="bold">Get Your Prescription</strong>
              <br />
              Once approved, your prescription is shipped straight to your door.
            </p>
          </div>
          <div className="mb16">
            <img src={SlidersIcon} />
            <p>
              <strong className="bold">Continuous Optimization</strong>
              <br />
              After your first month, we send another lab kit to ensure progress. Your doctor will adjust your dosage
              for optimal results.
            </p>
          </div>
        </div>

        <Divider className="mb16 mt16" />

        <a onClick={() => setOpenModalSideEffects(true)} className="link">
          Side Effects
        </a>

        <Divider className="mb16 mt16" />

        <a onClick={() => setOpenModalSafetyInfo(true)} className="link">
          Important Safety Information
        </a>
      </PaymentCard>

      {renderFooter()}

      <SetProductDialog
        isOpen={openModalSideEffects}
        title={<h4 className="subtitle bold mb0">Side Effects</h4>}
        text={subProduct.side_effects}
        onClose={() => setOpenModalSideEffects(false)}
        className="manage_subscription__cancel_modal align-left"
      />
      <SetProductDialog
        isOpen={openModalSafetyInfo}
        title={<h4 className="subtitle bold mb0">Important Safety Information</h4>}
        text={getProductInfo(values.selected_king_v2_product)}
        onClose={() => setOpenModalSafetyInfo(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </div>
  );
};

export default KingSubProductScreen;
