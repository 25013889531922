import { useEffect } from 'react';

export const useSegmentTracking = (viewEventName?: string, completionEventName?: string) => {
  useEffect(() => {
    if (window.analytics && viewEventName) {
      window.analytics.track(viewEventName, {
        url: window.location.href,
        timestamp: Date.now(),
      });
    }
  }, [viewEventName]);

  const trackCompletionEvent = (properties) => {
    if (window.analytics && completionEventName) {
      window.analytics.track(completionEventName, {
        url: window.location.href,
        timestamp: Date.now(),
        ...properties,
      });
    }
  };

  return { trackCompletionEvent };
};

export const trackEvent = (eventName: string, properties?: Record<string, any>) => {
  if (window.analytics) {
    window.analytics.track(eventName, {
      ...properties,
      timestamp: new Date().toISOString(),
    });
  }
};
