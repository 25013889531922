import React, { useState } from 'react';

import { P } from 'app/components/common/Typography';
import { useFormikContext } from 'formik';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import { AvailableProducts, WarriorSubProducts } from 'app/constants/Products';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import Divider from '@setproduct-ui/core/Divider';
import SlidersIcon from 'images/icons/sliders.svg';
import FreeshippingIcon from 'images/icons/fast-free-shipping-2.svg';
import CustomApproachIcon from 'images/icons/custom_approach_icon.svg';
import SetProductDialog from '@setproduct-ui/core/Dialog/Dialog';
import { PrimaryButton } from 'app/components/common/Button';
import { WARRIOR_MONTHLY_PRICE } from 'app/helpers/productSpecificPriceCalculators/warriorPriceCalculator';
import { WarriorSubProductsConfig } from 'app/components/customer/steps/Payment/warrior/WarriorSubProductConfig';
import { WarriorSafetyInfo } from 'app/components/customer/steps/Payment/warrior/WarriorSafetyInfo';
import WarriorSideEffectsInfo from 'app/components/customer/steps/Payment/warrior/WarriorSideEffectsInfo';
import WarriorSafetyAcknowledgment from 'app/components/customer/steps/Payment/warrior/WarriorSafetyAcknowledgment';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import subProductNames from 'app/utils/subProductNames';
import IngredientIcon from 'images/icons/ingredient.svg';
import AtHomeIcon from 'images/icons/at-home-monitoring-2.svg';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Accordion, Badge, Button, Card, IconList } from 'mui';

type Props = {
  handleSubmit: () => void;
};

const WarriorSubProductScreen = ({ handleSubmit }: Props) => {
  const newVersion = useRedesign();
  const [openModalSideEffects, setOpenModalSideEffects] = useState(false);
  const [openModalSafetyInfo, setOpenModalSafetyInfo] = useState(false);
  const [openModalAcknowledgment, setOpenModalAcknowledgment] = useState(false);
  const { values, setFieldValue } = useFormikContext<{
    warrior_strength: WarriorSubProducts;
  }>();

  const subProduct = WarriorSubProductsConfig[values.warrior_strength];

  const handleProductSelection = () => {
    if (values.warrior_strength === 'oral_minoxidil' || values.warrior_strength === 'topical_minoxidil') {
      handleSubmit();
    } else {
      setOpenModalAcknowledgment(true);
    }
  };

  const renderFooter = () => (
    <div className="fixed-footer">
      <P>Plans Starting at ${WARRIOR_MONTHLY_PRICE[values.warrior_strength][3] / 100}/mo</P>

      <PrimaryButton
        className="mt24 mb24"
        text="Continue"
        type="button"
        onClick={handleProductSelection}
        disabled={false}
        data-testid="submit-product-selection"
      />

      <P>
        <a onClick={() => setFieldValue('warrior_strength', false)} className="link">
          Explore Other Protocols
        </a>
      </P>
    </div>
  );

  const renderNewFooter = () => (
    <div className="fixed-footer">
      <div className="m-auto max-w-[26rem]">
        <p>Plans Starting at ${WARRIOR_MONTHLY_PRICE[values.warrior_strength][3] / 100}/mo</p>

        <Button
          className="mt-4 mb-4"
          type="button"
          onClick={handleSubmit}
          disabled={false}
          testid="submit-product-selection"
        >
          Continue with this Protocol
        </Button>

        <p>
          <a onClick={() => setFieldValue('warrior_strength', false)} className="no-underline">
            Explore Other Treatments
          </a>
        </p>
      </div>
    </div>
  );

  return newVersion ? (
    <>
      <Card>
        <Card.Body>
          <figure className="h-[180px] mb-4">
            <img
              className="h-full w-auto"
              src={ImagesMapping[AvailableProducts.Warrior][values.warrior_strength]}
              alt="king v2"
            />
          </figure>
          <div className="text-center">
            <Badge variant={AvailableProducts.Warrior} className="mb-2">
              {protocolNames[AvailableProducts.Warrior]}
            </Badge>
            <h4>{subProductNames[AvailableProducts.Warrior][values.warrior_strength]}</h4>
          </div>
        </Card.Body>
      </Card>
      <Card>
        <Card.Body>
          <h4>Treatment Details</h4>
          <p>{subProduct.recommended_for}</p>
          <Divider />
          <h4>Active Ingredients</h4>
          {subProduct.active_ingredients.map((ingredient) => (
            <>
              <IconList
                key={ingredient.name}
                items={[
                  {
                    title: ingredient.name,
                    text: ingredient.description,
                    icon: () => <img src={IngredientIcon} alt="ingredient" />,
                  },
                ]}
              />
              <hr />
            </>
          ))}
          <h4>What to Expect</h4>
          <IconList
            items={[
              {
                title: 'Check Your Levels',
                text: 'Receive your At-Home Lab Kit to check your testosterone levels quickly.',
                icon: () => <img src={AtHomeIcon} alt="levels" />,
              },
              {
                title: 'Get Your Prescription',
                text: 'Once approved, your prescription is shipped straight to your door.',
                icon: () => <img src={FreeshippingIcon} alt="free shipping" />,
              },
              {
                title: 'Continuous Optimization',
                text:
                  'After your first month, we send another lab kit to ensure progress. Your doctor will adjust your dosage\n' +
                  ' for optimal results.',
                icon: () => <img src={SlidersIcon} alt="at home monitoring" />,
              },
            ]}
          />
          <div className="-mb-8 md:-mb-12 lg:-mb-8">
            <hr />
            <Accordion
              items={[
                {
                  title: 'Side Effects',
                  content: <WarriorSideEffectsInfo sub_product={values.warrior_strength} />,
                },
                {
                  title: 'Important safety information',
                  content: <WarriorSafetyInfo sub_product={values.warrior_strength} />,
                },
              ]}
            />
          </div>
        </Card.Body>
      </Card>
      {renderNewFooter()}
    </>
  ) : (
    <div style={{ marginBottom: '200px' }}>
      <PaymentCard className="product-selection">
        <ProductHeader
          productName={AvailableProducts.Warrior}
          subProductName={values.warrior_strength}
          showTitle={true}
          cardStyle={false}
        />

        <Divider className="mt16 mb16" />

        <h5 className="subtitle">Recommended for</h5>
        <p>{subProduct.recommended_for}</p>

        <Divider className="mt16 mb16" />

        <h5 className="subtitle">Active Ingredients</h5>
        <p className="mh8">
          {subProduct.active_ingredients.map((ingredient) => (
            <p className="mb16" key={ingredient.name}>
              <strong className="bold">{ingredient.name}</strong>
              <br />
              {ingredient.description}
            </p>
          ))}
        </p>

        <Divider className="mb16" />

        <h5 className="subtitle">What to Expect</h5>

        <div className="what-to-expect">
          <div className="mb16">
            <img src={CustomApproachIcon} />
            <p>
              <strong className="bold">A custom approach</strong>
              <br />
              We'll learn about your needs and recommend a product that achieves your hair growth goals.
            </p>
          </div>
          <div className="mb16">
            <img src={FreeshippingIcon} />
            <p>
              <strong className="bold">Get Your Prescription</strong>
              <br />
              Once approved by your doctor, your prescription is shipped straight to your door.
            </p>
          </div>
          <div className="mb16">
            <img src={SlidersIcon} />
            <p>
              <strong className="bold">Continuous Optimization</strong>
              <br />
              After your begin, a doctor will work with you to further optimize your protocol based on your results.
            </p>
          </div>
        </div>

        <Divider className="mb16 mt16" />

        <a onClick={() => setOpenModalSideEffects(true)} className="link">
          Side Effects
        </a>

        <Divider className="mb16 mt16" />

        <a onClick={() => setOpenModalSafetyInfo(true)} className="link">
          Important Safety Information
        </a>
      </PaymentCard>

      {renderFooter()}

      <SetProductDialog
        isOpen={openModalSideEffects}
        title={<h4 className="subtitle bold mb0">Side Effects</h4>}
        text={<WarriorSideEffectsInfo sub_product={values.warrior_strength} />}
        onClose={() => setOpenModalSideEffects(false)}
        className="manage_subscription__cancel_modal align-left"
      />
      <SetProductDialog
        isOpen={openModalSafetyInfo}
        title={<h4 className="subtitle bold mb0">Important Safety Information</h4>}
        text={<WarriorSafetyInfo sub_product={values.warrior_strength} />}
        onClose={() => setOpenModalSafetyInfo(false)}
        className="manage_subscription__cancel_modal align-left"
      />
      <SetProductDialog
        isOpen={openModalAcknowledgment}
        title={<h4 className="subtitle bold mb0">Safety Information</h4>}
        text={<WarriorSafetyAcknowledgment handleSubmit={handleSubmit} />}
        onClose={() => setOpenModalAcknowledgment(false)}
        className="manage_subscription__cancel_modal align-left"
      />
    </div>
  );
};

export default WarriorSubProductScreen;
