import React, { useEffect, useState } from 'react';
import * as selectors from 'app/selectors/customer';
import { selectCustomer } from 'app/selectors/customer';
import { useAppDispatch, useAppSelector } from 'app/helpers/hooks';
import { useLocation } from 'react-router-dom';
import { push } from 'connected-react-router/immutable';
import { AvailableProducts, MultiMonthPlanMapping } from 'app/constants/Products';
import '../../../css/ManageSubscriptions.scss';
import SubscriptionWidget from './SubscriptionWidget';
import { Formik } from 'formik';
import { PrimaryButton } from 'app/components/common/Button';
import { Button, Card, PageWrapper } from 'mui';
import PageHeader from 'app/components/common/PageHeader';
import GenericBYOL from 'app/components/customer/steps/Payment/Generic/GenericBYOL';
import GenericSelfPay from 'app/components/customer/steps/Payment/Generic/GenericSelfPay';
import axios from 'axios';
import { getCSRF } from 'app/api';
import GenericDueToday from 'app/components/customer/steps/Payment/Generic/GenericDueToday';
import { apiRequestUserCommand } from 'app/actions/customer';
import updateSubscriptionSteps from 'app/utils/updateSubscriptionSteps';
import createCheckoutHelper from 'app/helpers/createCheckoutHelper';
import { ProductIntakeImm } from 'app/types/admin/customerUser';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import cx from 'classnames';
import { useExperiment } from 'app/utils/useExperiment';
import DiscountBanner from 'app/components/customer/Registration/KingRegistration/DiscountBanner';

const GenericUpdateSubscription = () => {
  const dispatch = useAppDispatch();
  const [byolFormSubmitted, setByolFormSubmitted] = useState(false);
  const [byolParams, setByolParams] = useState();

  const { search } = useLocation();
  const query_params = new URLSearchParams(search);

  const currentProductName = query_params.get('product') as AvailableProducts;
  const customer = useAppSelector(selectCustomer);
  const productsImm = useAppSelector(selectors.selectCustomerProducts);
  const product = productsImm.get(currentProductName);

  if (!product) throw new Error('No product');

  const intakes = product.get('intakes');
  const intake = intakes.get(intakes.size - 1) as ProductIntakeImm;

  const checkoutHelper = createCheckoutHelper(intake, customer, product, false, query_params);
  const multimonthEnabled = checkoutHelper.multimonthEnabled();

  const [currentStep, setCurrentStep] = useState(checkoutHelper.firstStep());
  const newVersion = useRedesign();

  const isNewYearDiscount = useExperiment('testosterone_new_year_discount', 'off') === 'experiment_without_variations';
  const isking = currentProductName === 'king';

  useEffect(() => {
    if (!isNewYearDiscount && isking) {
      dispatch(
        apiRequestUserCommand({
          cmdType: 'use_internal_experiment',
          params: {
            experiment_id: 'testosterone_new_year_discount',
          },
        }),
      );
    }

    if (currentStep === updateSubscriptionSteps.productStep) {
      checkoutHelper.trackRecommendationGenerated();
    }
  }, [currentStep]);

  const uploadLabResults = async (params) => {
    if (params === undefined) return;

    await axios.post(
      '/api/commands',
      {
        ...params,
        user_id: customer.get('id'),
        intake_name: intake.get('name'),
        photo_purpose: `${currentProductName}_onboarding_lab`,
        cmdType: 'upload_own_lab_results',
        type: 'upload_own_lab_results',
      },
      { headers: { 'X-CSRF-Token': getCSRF() } },
    );
  };

  const onSubmit = (params: any) => {
    const action = apiRequestUserCommand({
      cmdType: 'update_subscription',
      params: {
        ...params,
        intake_name: intake.get('name'),
      },
      context: {
        onSuccessActionCreator: () => {
          uploadLabResults(byolParams);

          return push(checkoutHelper.redirectTo(params));
        },
      },
    });

    dispatch(action);
  };

  const initialValues = {
    selfPay: false,
    refundPolicy: false,
    include_supplement:
      !checkoutHelper.supplementsOnAnotherIntake() && product.get('opt_in_choice')?.get('include_supplement'),
    include_dermastamp: product.get('opt_in_choice')?.get('include_dermastamp'),
    multimonth_plan: product.get('opt_in_choice')?.get('multimonth_plan') || 1,
    use_own_lab: product.get('use_own_lab'),
    own_lab_file_uploaded: customer.get('own_lab_file_uploaded'),
    ...checkoutHelper.productSpecificInitialValues(),
    is_new_year_discount: isNewYearDiscount && isking,
  };

  const productStep = (values) => (
    <>
      <PageHeader title="Choose Your Product" className="plan_selection__header mb48 -edged" />
      <div
        className={cx({
          'flex flex-col gap-y-6 lg:gap-y-8': newVersion,
          'mb-60': checkoutHelper.recommendationSystemEnabled() && checkoutHelper.subProductName(values),
        })}
      >
        {checkoutHelper.renderProductStep(false, () => setCurrentStep(checkoutHelper.secondStep()))}
      </div>
      {!newVersion && (
        <PrimaryButton text="CONTINUE" type="button" onClick={() => setCurrentStep(checkoutHelper.secondStep())} />
      )}
    </>
  );

  const applyNewYearDiscount = (multimonth_plan: number) => {
    if (!isking) return;
    let promo_code = '';

    switch (multimonth_plan) {
      case 12:
        promo_code = 'ny15pofft';
        break;
      case 3:
        promo_code = 'ny10pofft';
        break;
      default:
        promo_code = 'ny25pofft';
    }
    try {
      const action = apiRequestUserCommand({
        cmdType: 'create_discount_with_promo_code',
        params: {
          promo_code,
          product_name: currentProductName,
          multimonth_plan,
        },
      });
      dispatch(action);
    } catch (e) {
      console.log('error', e);
    }
  };

  const multimonthStep = (values) => (
    <>
      <PageHeader title="Choose Your Plan" className="plan_selection__header mb48 -edged" />
      {checkoutHelper.renderMultimonthStep(() => setCurrentStep(updateSubscriptionSteps.productStep))}
      {newVersion ? (
        <Button
          onClick={() => {
            applyNewYearDiscount(values.multimonth_plan);
            setCurrentStep(updateSubscriptionSteps.paymentStep);
          }}
        >
          Continue
        </Button>
      ) : (
        <PrimaryButton
          text="CONTINUE"
          type="button"
          onClick={() => {
            applyNewYearDiscount(values.multimonth_plan);
            setCurrentStep(updateSubscriptionSteps.paymentStep);
          }}
        />
      )}
    </>
  );

  const checkoutStep = (handleSubmit, dirty, values) => {
    const priceCalculator = checkoutHelper.priceCalculator(values);
    return newVersion ? (
      <>
        <div className="px-4">
          <h3 className="mb-3">Confirm Plan Update</h3>
          {checkoutHelper.checkoutBreadcrumbs(
            dispatch,
            () => setCurrentStep(updateSubscriptionSteps.productStep),
            () => setCurrentStep(updateSubscriptionSteps.planStep),
          )}
        </div>
        <Card>
          <Card.Body>
            <SubscriptionWidget
              currentProductName={currentProductName}
              product={product}
              priceCalculator={priceCalculator}
              goToSelectProductPage={() => setCurrentStep(updateSubscriptionSteps.productStep)}
              multimonthPeriod={MultiMonthPlanMapping[values.multimonth_plan]}
              multimonthPlan={values.multimonth_plan}
              isOnboarding={false}
              supplementsOnAnotherIntake={checkoutHelper.supplementsOnAnotherIntake()}
              activeProductWithSupplementName={checkoutHelper.activeProductWithSupplementName()}
              checkoutHelper={checkoutHelper}
              multimonthEnabled={multimonthEnabled}
              productChanged={checkoutHelper.productChanged(values)}
              subProductName={checkoutHelper.subProductName(values)}
            />
            {checkoutHelper.showLabAndDueTodaySection(values) ? (
              <>
                <GenericBYOL
                  kingV2OralTrt={checkoutHelper.isKingV2OralTrt(values.selected_king_v2_product)}
                  priceCalculator={priceCalculator}
                  intake={intake}
                  byolParams={byolParams}
                  setByolParams={setByolParams}
                  setByolFormSubmitted={setByolFormSubmitted}
                  byolFormSubmitted={byolFormSubmitted}
                />
                <GenericDueToday
                  multimonthPeriod={values.multimonth_plan}
                  isOnboarding={false}
                  priceCalculator={priceCalculator}
                  showLabKit={checkoutHelper.showLabAndDueTodaySection(values)}
                  intake={intake}
                />
              </>
            ) : (
              <GenericDueToday
                title="Summary"
                multimonthPeriod={values.multimonth_plan}
                isOnboarding={false}
                priceCalculator={priceCalculator}
                showLabKit={checkoutHelper.showLabAndDueTodaySection(values)}
                intake={intake}
              />
            )}
            <GenericSelfPay />
            <Button
              type="button"
              disabled={
                !dirty ||
                !values.selfPay ||
                checkoutHelper.specialDisabledConditions(values, { byolFormSubmitted: byolFormSubmitted })
              }
              onClick={handleSubmit}
            >
              Update Subscription
            </Button>
          </Card.Body>
        </Card>
      </>
    ) : (
      <>
        <PageHeader title={'Confirm Plan Update'} className="plan_selection__header mb48 -edged" />
        <SubscriptionWidget
          currentProductName={currentProductName}
          product={product}
          priceCalculator={priceCalculator}
          goToSelectProductPage={() => setCurrentStep(updateSubscriptionSteps.productStep)}
          multimonthPeriod={MultiMonthPlanMapping[values.multimonth_plan]}
          multimonthPlan={values.multimonth_plan}
          isOnboarding={false}
          supplementsOnAnotherIntake={checkoutHelper.supplementsOnAnotherIntake()}
          activeProductWithSupplementName={checkoutHelper.activeProductWithSupplementName()}
          checkoutHelper={checkoutHelper}
          multimonthEnabled={multimonthEnabled}
          productChanged={checkoutHelper.productChanged(values)}
          subProductName={checkoutHelper.subProductName(values)}
          onMultimonthPlanChange={applyNewYearDiscount}
        />
        {checkoutHelper.showLabAndDueTodaySection(values) ? (
          <>
            <GenericBYOL
              kingV2OralTrt={checkoutHelper.isKingV2OralTrt(values.selected_king_v2_product)}
              priceCalculator={priceCalculator}
              intake={intake}
              byolParams={byolParams}
              setByolParams={setByolParams}
              setByolFormSubmitted={setByolFormSubmitted}
              byolFormSubmitted={byolFormSubmitted}
            />
            <GenericDueToday
              multimonthPeriod={values.multimonth_plan}
              isOnboarding={false}
              priceCalculator={priceCalculator}
              showLabKit={checkoutHelper.showLabAndDueTodaySection(values)}
              intake={intake}
            />
          </>
        ) : (
          <GenericDueToday
            title="Summary"
            multimonthPeriod={values.multimonth_plan}
            isOnboarding={false}
            priceCalculator={priceCalculator}
            showLabKit={checkoutHelper.showLabAndDueTodaySection(values)}
            intake={intake}
          />
        )}
        <GenericSelfPay />
        <PrimaryButton
          text="UPDATE SUBSCRIPTION"
          type="button"
          disabled={
            !dirty ||
            !values.selfPay ||
            checkoutHelper.specialDisabledConditions(values, { byolFormSubmitted: byolFormSubmitted })
          }
          onClick={handleSubmit}
        />
      </>
    );
  };

  const renderCurrentStep = (handleSubmit, dirty, values) => {
    switch (currentStep) {
      case updateSubscriptionSteps.productStep:
        return productStep(values);
      case updateSubscriptionSteps.planStep:
        return multimonthStep(values);
      case updateSubscriptionSteps.paymentStep:
        return checkoutStep(handleSubmit, dirty, values);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} enableReinitialize>
      {({ values, dirty, handleSubmit }) =>
        newVersion ? (
          <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
            {isking && <DiscountBanner />}
            {renderCurrentStep(handleSubmit, dirty, values)}
          </PageWrapper>
        ) : (
          <div className="centered manage_subscription flex-column pb52">
            <div className="payment_method__flex yolo updated-design">
              {isking && <DiscountBanner />}
              {renderCurrentStep(handleSubmit, dirty, values)}
            </div>
          </div>
        )
      }
    </Formik>
  );
};

export default GenericUpdateSubscription;
