import React from 'react';
import { CustomerUserImm, LoverIntake, LoverProduct } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';
import BaseCheckoutHelper from 'app/components/customer/steps/Payment/Generic/BaseCheckoutHelper';
import { AvailableProducts, LoverSubProducts } from 'app/constants/Products';
import LoverPriceCalculator from 'app/helpers/productSpecificPriceCalculators/loverPriceCalculator';
import PriceCalculator from 'app/helpers/priceCalculator';
import LoverTreatmentPlan from 'app/components/customer/steps/Payment/lover/LoverTreatmentPlan';
import LoverMultimonthPlanSelectionForm from 'app/components/customer/steps/Payment/lover/LoverMultimonthPlanSectionForm';
import updateSubscriptionSteps from 'app/utils/updateSubscriptionSteps';
import { useExperiment } from 'app/utils/useExperiment';
import { push } from 'connected-react-router/immutable';

class LoverCheckoutHelper extends BaseCheckoutHelper {
  intake: ImmutableMap<LoverIntake>;
  product: ImmutableMap<LoverProduct>;

  constructor(
    intake: ImmutableMap<LoverIntake>,
    customer: CustomerUserImm,
    product: ImmutableMap<LoverProduct>,
    isOnboarding: boolean,
  ) {
    super(customer, intake, isOnboarding);

    this.product = product;
    this.intake = intake;
  }

  updateSubProductCommandName = 'select_lover_product';

  multimonthPlansOptions = (_subProductName) => [
    {
      value: 1,
      label: 'Monthly Plan',
    },
    {
      value: 3,
      label: 'Quarterly Plan',
    },
    {
      value: 12,
      label: 'Yearly Plan',
    },
  ];

  currentProductName = () => AvailableProducts.Lover;

  firstStep = () => {
    return this.multimonthEnabled() ? updateSubscriptionSteps.productStep : updateSubscriptionSteps.paymentStep;
  };

  showChangeProductButton = () => true;

  productSpecificInitialValues = () => {
    const numberOfDoses = this.subProductChoice()?.get('number_of_doses');

    return {
      daily_strength:
        this.subProductChoice()?.get('daily_strength') || (!numberOfDoses ? LoverSubProducts['2_5_mg'] : null),
      number_of_doses: numberOfDoses,
      multimonth_plan: this.product?.get('opt_in_choice')?.get('multimonth_plan') || 12,
    };
  };

  subProductChoice = () => this.product.get('opt_in_choice')?.get('sub_product_choice');

  otherActiveSubscription = () => {
    const activeProductNames = this.customer.get('active_products_names');
    return (
      activeProductNames.count() > 0 &&
      (activeProductNames.count() > 1 || activeProductNames[0] != AvailableProducts.Lover)
    );
  };

  priceCalculator = (values: any, _subscription_paid: boolean = false, _lab_paid: boolean = false) => {
    const variation = useExperiment('bloodflow_multimonth', 'bloodflow_multimonth_variation_0');

    const loverPriceCalculator = new LoverPriceCalculator(
      values.include_supplement,
      this.supplementsOnAnotherIntake(),
      this.intake.get('name'),
      values.daily_strength,
      values.number_of_doses,
      values.multimonth_plan,
      this.otherActiveSubscription(),
      variation,
    );

    return new PriceCalculator(loverPriceCalculator, this.product.get('discounts'));
  };

  renderProductStep = (_firstTimeChoice: boolean = false, handleSubmit: any) => (
    <LoverTreatmentPlan product={this.product} handleSubmit={handleSubmit} />
  );

  renderMultimonthStep = (handleChangeProduct) => (
    <LoverMultimonthPlanSelectionForm handleChangeProduct={handleChangeProduct} saveDataOnChange={this.isOnboarding} />
  );

  selectProductStepPath = () => 'sub_product_selection';
  selectPlanStepPath = () => 'multimonth_plan_selection';

  checkoutBreadcrumbs = (dispatch, onPlanChange: any = null, onProductChange: any = null): any => {
    onProductChange ||= () => dispatch(push(this.selectProductStepPath()));
    onPlanChange ||= () => dispatch(push(this.selectPlanStepPath()));

    return (
      <p>
        <a data-testid="change-product" onClick={onProductChange}>
          Change Product
        </a>
        {' | '}
        <a data-testid="change-plan" onClick={onPlanChange}>
          Change Plan
        </a>
      </p>
    );
  };

  multimonthEnabled = () => true;
}

export default LoverCheckoutHelper;
