import React from 'react';
import { useHistory } from 'react-router-dom';
import { DrawerMenu as MenuComponent } from 'mui';
import { BOTTOM_LINKS, LOGGED_IN_TOP, LOGGED_OUT_TOP } from 'app/constants/Menu';
import { logout } from 'app/actions/customer';
import { useAppDispatch } from 'app/helpers/hooks';

const DrawerMenu = ({ loggedIn }) => {
  const history = useHistory();
  const topItems = loggedIn ? LOGGED_IN_TOP : LOGGED_OUT_TOP;
  const dispatch = useAppDispatch();

  const onClickHandler = (e, item) => {
    if (item.raw) {
      return undefined;
    } else if (item.type === 'logout') {
      dispatch(logout());
    } else {
      e.preventDefault();
      history.push(item.path);
    }
    const drawerInput = document.getElementById('main-nav-drawer') as HTMLInputElement;
    drawerInput.checked = false;
  };

  const toMenuItems = (items) =>
    items.map((item) => (
      <button key={item.title} onClick={(e) => onClickHandler(e, item)}>
        {item.title}
      </button>
    ));

  return <MenuComponent primaryItems={toMenuItems(topItems)} secondaryItems={toMenuItems(BOTTOM_LINKS)} />;
};

export default DrawerMenu;
