import React, { useContext } from 'react';
import { useAppSelector } from 'app/helpers/hooks';
import { selectManagedProduct, selectCurrentIntakeProduct } from 'app/selectors/customer';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import './css/DiscountCouponsList.scss';
import { ProductContext } from 'app/components/customer/Product';
import * as selectors from 'app/selectors/customer';
import { useExperiment } from 'app/utils/useExperiment';

const CouponItem = ({ key, discount, priceCalculator, isLabsFree, hideCouponCode = false }) => {
  const isLab = discount?.get('product_name')?.slice(-4) === '_lab';
  const appliedFor = isLab ? 'labs' : 'subscription';

  const price = priceCalculator.discountPrice(discount);

  return price > 0 ? (
    <div key={key} className="price_row mt24">
      <div className={hideCouponCode ? 'coupon_code' : ''}>
        {isLabsFree
          ? `“${discount.get('coupon').get('code').toUpperCase()}” applied.`
          : hideCouponCode
            ? ''
            : `“${discount.get('coupon').get('code')}” applied for ${appliedFor}.`}
        {!hideCouponCode && <br />}
        {discount.get('coupon').get('name')}
      </div>
      <PriceFormatter price={price} showCents={true} negative={true} />
    </div>
  ) : null;
};

const DiscountCouponsList = ({ priceCalculator }) => {
  const productCtx = useContext(ProductContext);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, productCtx?.selectedProduct));
  const labsFreeExperiment =
    useExperiment('testosterone_discount_labs_free', 'testosterone_discount_labs_free_variation_0') ===
    'testosterone_discount_labs_free_variation_1';
  let discounts = product.get('discounts');
  const currentIntake = useAppSelector(selectors.selectCurrentIntake);
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);
  const isOnboarding = currentIntake?.get('type') === 'onboarding';
  const isLabsFree = isOnboarding && currentProductName === 'king' && labsFreeExperiment;

  if (discounts == null) {
    const currentProduct = useAppSelector(selectManagedProduct);

    discounts = currentProduct.get('intakes').last().get('discounts');
  }

  if (priceCalculator?.productSpecificPriceCalculator?.is_new_year_discount) {
    discounts = priceCalculator.discounts;
  }

  discounts = discounts?.reduce((acc, discount) => {
    if (
      !(
        isLabsFree &&
        discount &&
        discount.get('type') === 'Discounts::SubscriptionPaymentsOff' &&
        discount.get('params').get('percentage_off') === 100
      )
    ) {
      acc.push(discount);
    }
    return acc;
  }, []);

  return (
    <div className="discount-coupons-list">
      {discounts?.map((discount) => (
        <CouponItem
          key={discount.get('id')}
          discount={discount}
          priceCalculator={priceCalculator}
          isLabsFree={isLabsFree}
          hideCouponCode={['ny10pofft', 'ny15pofft', 'ny25pofft'].includes(discount.get('coupon').get('code'))}
        />
      ))}
    </div>
  );
};

export default DiscountCouponsList;
