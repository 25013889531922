import React, { createContext, useContext, useEffect, useState } from 'react';
import { RedesignControl } from 'app/utils/redesign/RedesignControl';
import { isStagingOrLower } from 'app/helpers/env';
import { useAppSelector } from 'app/helpers/hooks';
import { selectAuthentication } from 'app/selectors/customer';

const RedesignContext = createContext(false);

const DISABLED_PATHS = ['admin', 'login'];

export const RedesignProvider = ({ children }) => {
  const key = 'Maximus2024DesignVersion';
  const [isNewVersion, setIsNewVersion] = useState(() => {
    const savedFlag = localStorage.getItem(key);
    const disabled = DISABLED_PATHS.some((str) => window.location.pathname.includes(str));
    return savedFlag === 'true' && !disabled;
  });

  useEffect(() => {
    const element = document.querySelector('html');

    element?.classList?.toggle('old-theme', !isNewVersion);
    element?.classList?.toggle('new-theme', isNewVersion);
  }, [isNewVersion]);

  const toggleTheme = async (value) => {
    setIsNewVersion(value);
    localStorage.setItem(key, value.toString());
  };

  const authentication = useAppSelector(selectAuthentication);
  const isAdmin = authentication === 'admin';
  const showRedesignControl = !isAdmin && isStagingOrLower();

  return (
    <RedesignContext.Provider value={isNewVersion}>
      {showRedesignControl && <RedesignControl onChange={toggleTheme} />}
      {children}
    </RedesignContext.Provider>
  );
};

export const useRedesign = () => useContext(RedesignContext);
