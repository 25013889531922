import React from 'react';

import '../css/ProductSelection.scss';
import { useFormikContext } from 'formik';
import { AvailableProducts, MagicianSubProducts } from 'app/constants/Products';
import GenericProductSelectionForm from 'app/components/customer/steps/Payment/Generic/GenericProductSelectionForm';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import subProductNames from 'app/utils/subProductNames';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Badge, Card, Button } from 'mui';
import Radio from 'app/components/common/Radio';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';

type Props = {
  firstTimeChoice?: boolean;
  handleSubmit?: () => void;
  changeProductHandler?: ((kingProductType: string, setFieldValue: any) => void) | null;
};

const MagicianProductSelectionForm = ({
  firstTimeChoice = true,
  changeProductHandler = null,
  handleSubmit = undefined,
}: Props) => {
  const { values, setFieldValue } = useFormikContext<{
    drug_variant: string;
    strength_variant: string;
  }>();

  const handleChange = (value: string) => {
    setFieldValue('drug_variant', value);

    const strenghtVariant = value === MagicianSubProducts.Semaglutide ? '0_25mg' : '2_5mg';
    setFieldValue('strength_variant', strenghtVariant);

    if (changeProductHandler) {
      changeProductHandler(value, setFieldValue);
    }
  };

  const subProductsConfig = [
    {
      name: MagicianSubProducts.Semaglutide,
      price: 24999,
      description:
        'Unlock a slimmer you with Semaglutide - proven to help shed an average of 16.9% body weight in 68 weeks.',
    },
    {
      name: MagicianSubProducts.Tirzepatide,
      price: 39999,
      description:
        'Discover the most effective weight loss tool on the market. People using Tirzepatide lose more weight than any other GLP-1 product. Tirzepatide patients lose on average 22.5% of body weight in 72 weeks.',
    },
  ];

  const renderRadio = (selectedSubProduct, subProduct, showDivider) => (
    <>
      <Radio
        className="no-borders top-aligned"
        onChange={() => handleChange(subProduct.name)}
        checked={selectedSubProduct === subProduct.name}
        disabled={subProduct.notAvailableInState}
        testId={`drug-variant-${subProduct.name}`}
        label={
          <div className="-mt-6 lg:-mt-5">
            <div className="flex flex-row justify-between items-end">
              <h5>{subProductNames[AvailableProducts.Magician][subProduct.name]}</h5>
              <PriceFormatter period="mo" from price={subProduct.price} />
            </div>
            <p className="mt-2 lg:mt-3 text-sm lg:text-base">{subProduct.description}</p>
          </div>
        }
      />
      {showDivider && <hr />}
    </>
  );

  const newVersion = useRedesign();

  return newVersion ? (
    <Card>
      <Card.Body>
        <figure className="h-[180px] mb-4">
          <img className="h-full w-auto" src={ImagesMapping[AvailableProducts.Magician][values.drug_variant]} />
        </figure>

        <div className="text-center mt-4">
          <Badge variant={AvailableProducts.Magician} className="mb-2">
            {protocolNames[AvailableProducts.Magician]}
          </Badge>
        </div>

        {subProductsConfig.map((subProduct, index) =>
          renderRadio(values.drug_variant, subProduct, index !== subProductsConfig.length - 1),
        )}
        {handleSubmit && (
          <>
            <hr />

            <Button onClick={handleSubmit} testId="submit-product-selection">
              Continue
            </Button>
          </>
        )}
      </Card.Body>
    </Card>
  ) : (
    <div className="choose_product">
      <GenericProductSelectionForm
        productName={AvailableProducts.Magician}
        selectedSubProduct={values.drug_variant as MagicianSubProducts}
        firstTimeChoice={firstTimeChoice}
        subProductsConfig={subProductsConfig}
        handleChange={handleChange}
      />
      <div className="mt32" />
    </div>
  );
};

export default MagicianProductSelectionForm;
