import { CustomerUserImm, WarriorIntake, WarriorProduct } from 'app/types/admin/customerUser';
import { ImmutableMap } from 'app/types/admin';
import BaseCheckoutHelper from 'app/components/customer/steps/Payment/Generic/BaseCheckoutHelper';
import { AvailableProducts, WarriorSubProducts } from 'app/constants/Products';
import WarriorPriceCalculator from 'app/helpers/productSpecificPriceCalculators/warriorPriceCalculator';
import PriceCalculator from 'app/helpers/priceCalculator';
import WarriorProductSelectionForm from 'app/components/customer/steps/Payment/warrior/WarriorProductSelectionForm';
import React from 'react';
import PageHeader from 'app/components/common/PageHeader';
import updateSubscriptionSteps from 'app/utils/updateSubscriptionSteps';
import { push } from 'connected-react-router/immutable';

class WarriorCheckoutHelper extends BaseCheckoutHelper {
  intake: ImmutableMap<WarriorIntake>;
  product: ImmutableMap<WarriorProduct>;

  constructor(
    customer: CustomerUserImm,
    intake: ImmutableMap<WarriorIntake>,
    product: ImmutableMap<WarriorProduct>,
    isOnboarding: boolean,
  ) {
    super(customer, intake, isOnboarding);

    this.intake = intake;
    this.product = product;
  }

  currentProductName = () => AvailableProducts.Warrior;

  productSpecificInitialValues = () => {
    return { warrior_strength: this.recommendedSubProduct() || WarriorSubProducts.OralMinoxidil };
  };

  recommendedSubProduct = () => this.intake.get('recommended_sub_product');

  recommendationSystemEnabled = () => true;

  checkoutBreadcrumbs = (dispatch, _onPlanChange: any = null, onProductChange: any = null): any => {
    onProductChange ||= () => dispatch(push(this.selectPlanStepPath()));

    return (
      <p>
        <a data-testid="change-product" onClick={onProductChange}>
          Change Product
        </a>
      </p>
    );
  };

  renderProductStep = (firstTimeChoice: boolean = false, handleSubmit: any = null) => {
    return (
      <WarriorProductSelectionForm
        firstTimeChoice={firstTimeChoice}
        handleSubmit={handleSubmit}
        availableSubProducts={this.intake.get('available_sub_products')}
      />
    );
  };

  subProductNameForIntakeCheckout = () =>
    this.product?.get('opt_in_choice')?.get('warrior_strength') || WarriorSubProducts.OralMinoxidil;

  updateSubProductCommandName = 'select_warrior_sub_product';

  showChangeProductButton = () => true;

  renderProductStepHeader = (values): any => {
    if (values.warrior_strength) {
      if (values.warrior_strength == this.recommendedSubProduct()) {
        return <PageHeader title="Recommended Protocol" className="treatment_plan__header mb24 -edged" />;
      } else {
        return null;
      }
    }

    return <PageHeader title="Choose a Protocol" className="treatment_plan__header mb24 -edged" />;
  };

  priceCalculator = (values: any, _subscription_paid: boolean = false, _lab_paid: boolean = false) => {
    const warriorPriceCalculator = new WarriorPriceCalculator(
      values.include_supplement,
      this.supplementsOnAnotherIntake(),
      this.intake.get('name'),
      this.subProductName(values) || this.subProductNameForIntakeCheckout(),
      values.include_dermastamp,
    );

    return new PriceCalculator(warriorPriceCalculator, this.product.get('discounts'));
  };

  showProductSelectionStepNextButton = () => false;

  firstStep = () => updateSubscriptionSteps.productStep;

  secondStep = () => updateSubscriptionSteps.paymentStep;

  subProductName = (values) => values.warrior_strength;

  productChanged = (values) => this.currentSubscriptionProduct() !== values.warrior_strength;

  currentSubscriptionProduct = () => this.product?.get('opt_in_choice')?.get('warrior_strength');

  selectProductStepPath = () => 'sub_product_selection';

  showMultimonthHeader = () => true;

  displayDermaOption = () => true;
}

export default WarriorCheckoutHelper;
