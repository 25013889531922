import React, { useRef, useEffect } from 'react';
import * as Braze from '@braze/web-sdk';
import { Switch, Redirect, Route } from 'react-router';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'app/helpers/hooks';
import { ProductProvider } from 'app/components/customer/Product/provider';
import Header from 'app/components/customer/Header';
import BlackSettings from 'app/components/customer/Settings';
import ContactUs from 'app/components/customer/ContactUs';
import Dashboard from 'app/components/customer/dashboard';
import * as Routes from 'app/constants/Routes';
import Footer from 'app/components/customer/Footer';
import RefillCheckpoint from 'app/components/customer/steps/RefillCheckpoint';
import BillingPreferences from 'app/components/customer/dashboard/BillingPreferences';
import ManageSubscriptions from 'app/components/customer/dashboard/ManageSubscriptions';
import GenericUpdateSubscription from 'app/components/customer/steps/Payment/Generic/GenericUpdateSubscription';
import PasswordPreferences from 'app/components/customer/dashboard/PasswordPreferences';
import BillingIssueModal from 'app/components/customer/dashboard/BillingIssueModal';
import CustomerMenu from 'app/components/customer/CustomerMenu';
import CongratsKing from 'app/components/customer/Congrats/King';
import CongratsMagician from 'app/components/customer/Congrats/Magician';

import AppointmentPage from 'app/components/customer/AppointmentPage';
import LabCheckoutPage from 'app/components/customer/LabCheckoutPage';
import { selectCustomerId, selectLatestPaymentMethodErrorMessage } from 'app/selectors/customer';
import Intake from './Intake';

import ContinueOnboarding from './ContinueOnboarding';
import './css/Customer.scss';
import UseOwnLabStep from 'app/components/customer/steps/UseOwnLabStep';
import AnnualLabCheckout from 'app/components/customer/dashboard/AnnualLabCheckout';
import LabCheckoutSuccessPage from 'app/components/customer/LabCheckoutSuccessPage';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Drawer, NavBar } from 'mui';
import DrawerMenu from 'app/components/common/DrawerMenu';

const TrackGrowthAsync = React.lazy(() => import('app/components/customer/steps/TrackGrowth'));
const UpdateQuestionnaireAsync = React.lazy(() => import('app/components/customer/Product/UpdateQuestionnaire'));
const UpdateQuestionnaireConfirmationAsync = React.lazy(
  () => import('app/components/customer/Product/UpdateQuestionnaire/Confirmation'),
);

const Customer = () => {
  const mainRef = useRef<HTMLElement>(null);
  const { pathname } = useLocation();
  const paymentErrorMessageStripe = useAppSelector(selectLatestPaymentMethodErrorMessage);
  const customerId = useAppSelector(selectCustomerId);
  const onAllowedPages =
    !pathname.includes('billing') && !pathname.includes('payment') && !pathname.includes('confirm_shipping');

  const newTheme = useRedesign();

  useEffect(() => {
    if (newTheme) {
      window.scrollTo(0, 0);
    } else if (mainRef.current && mainRef.current.scrollTo) {
      mainRef.current.scrollTo(0, 0);
    }

    if (window.brazeSdkApiKey && window.brazeSdkHost) {
      Braze.initialize(window.brazeSdkApiKey, { baseUrl: window.brazeSdkHost });
      Braze.changeUser(customerId);
      Braze.openSession();
    }
  }, [pathname]);

  const NavigationWrapper = newTheme ? Drawer : React.Fragment;
  const navigationWrapperProps = newTheme ? { sideContent: <DrawerMenu loggedIn /> } : {};

  return (
    <div className="grid-container">
      <ProductProvider>
        <NavigationWrapper {...navigationWrapperProps}>
          {newTheme ? (
            <NavBar wrapperClassName="" />
          ) : (
            <Header style="black" loggedIn>
              <CustomerMenu />
            </Header>
          )}
          <main ref={mainRef}>
            <Switch>
              <Route exact path={Routes.ClinicalQuestion} component={RefillCheckpoint} />
              <Route exact path={Routes.Dashboard} component={Dashboard} />
              <Route exact path={Routes.ContinueOnboarding} component={ContinueOnboarding} />
              <Route exact path={Routes.ContinueIntake} component={ContinueOnboarding} />
              <Route
                exact
                path={Routes.UpdateQuestionnaire + '/complete'}
                component={UpdateQuestionnaireConfirmationAsync}
              />
              <Route path={Routes.UpdateQuestionnaire} component={UpdateQuestionnaireAsync} />
              <Route exact path={Routes.Intake} component={Intake} />
              <Route exact path={Routes.Settings} component={BlackSettings} />
              <Route exact path={Routes.ContactUs} component={ContactUs} />
              <Route exact path={Routes.BillingSettings} component={BillingPreferences} />
              <Route exact path={Routes.WarriorRetakePhotos} component={TrackGrowthAsync} />
              <Route exact path={Routes.ManageSubscription} component={ManageSubscriptions} />
              <Route exact path={Routes.UpdateSubscription} component={GenericUpdateSubscription} />
              <Route exact path={Routes.PasswordSettings} component={PasswordPreferences} />
              <Route exact path={Routes.CongratsKing} component={CongratsKing} />
              <Route exact path={Routes.CongratsMagician} component={CongratsMagician} />
              <Route exact path={Routes.ScheduleAppointment} component={AppointmentPage} />
              <Route exact path={Routes.LabCheckout} component={LabCheckoutPage} />
              <Route exact path={Routes.LabCheckoutSuccess} component={LabCheckoutSuccessPage} />
              <Route exact path={Routes.UploadOwnLabs} component={UseOwnLabStep} />
              <Route exact path={Routes.AnnualLabCheckout} component={AnnualLabCheckout} />
              <Route path={Routes.Dashboard} render={() => <Redirect to={Routes.Dashboard} />} />
            </Switch>
          </main>
          <Footer />
          {paymentErrorMessageStripe && onAllowedPages && <BillingIssueModal message={paymentErrorMessageStripe} />}
        </NavigationWrapper>
      </ProductProvider>
    </div>
  );
};

export default Customer;
