import React from 'react';

import { useFormikContext } from 'formik';
import { AvailableProducts, WarriorSubProducts } from 'app/constants/Products';
import { ImmutableList } from 'app/types/admin';
import ProductHeader from 'app/components/customer/steps/Payment/king/ProductHeader';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import { SecondaryButton } from 'app/components/common/Button';
import { P } from 'app/components/common/Typography';
import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import subProductNames from 'app/utils/subProductNames';
import { WARRIOR_MONTHLY_PRICE } from 'app/helpers/productSpecificPriceCalculators/warriorPriceCalculator';
import { WarriorSubProductsConfig } from 'app/components/customer/steps/Payment/warrior/WarriorSubProductConfig';
import { ImagesMapping } from 'app/components/customer/steps/Payment/king/ProductImage';
import protocolNames from 'app/utils/protocolNames';
import { Badge, Button, Card } from 'mui';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import Divider from '@setproduct-ui/core/Divider';
import cx from 'classnames';

type Props = {
  availableSubProducts: ImmutableList<WarriorSubProducts> | null;
};

const WarriorSubProductsList = ({ availableSubProducts }: Props) => {
  const { setFieldValue } = useFormikContext();
  const newVersion = useRedesign();

  const renderProductCard = (subProductName: any) => {
    const subProduct = WarriorSubProductsConfig[subProductName];

    return newVersion ? (
      <Card>
        <Card.Body>
          <figure className="h-[180px] mb-4">
            <img
              className="h-full w-auto"
              src={ImagesMapping[AvailableProducts.Warrior][subProductName]}
              alt="warrior v2"
            />
          </figure>
          <div className="text-center">
            <Badge variant={AvailableProducts.Warrior} className="mb-2">
              {protocolNames[AvailableProducts.Warrior]}
            </Badge>
            <h4>{subProductNames[AvailableProducts.Warrior][subProductName]}</h4>
          </div>
          <P>
            <b className="bold">Recommended for: </b>
            {subProduct.recommended_for}
          </P>
          <Divider />
          <P className="text-center flex flex-row flex-center gap-x-1 mb-2">
            Plans starting at
            <PriceFormatter period="mo" size="small" price={WARRIOR_MONTHLY_PRICE[subProductName][3]} />
          </P>
          <Button data-testid="own-lab-modal-close" onClick={() => setFieldValue('warrior_strength', subProductName)}>
            Continue
          </Button>
        </Card.Body>
      </Card>
    ) : (
      <PaymentCard className="product-selection mb32">
        <ProductHeader
          productName={AvailableProducts.Warrior}
          subProductName={subProductName}
          cardStyle={false}
          smallImage={true}
        />
        <div className="price_row mb16">
          <h4 className="subtitle mt8">{subProductNames[AvailableProducts.Warrior][subProductName]}</h4>
          <PriceFormatter price={WARRIOR_MONTHLY_PRICE[subProductName][3]} from={true} />
        </div>
        <P>
          <b className="bold">Recommended for: </b>
          {subProduct.recommended_for}
        </P>
        <SecondaryButton
          text="Continue"
          className="mt16"
          data-testid="own-lab-modal-close"
          onClick={() => setFieldValue('warrior_strength', subProductName)}
        />
      </PaymentCard>
    );
  };

  if (!availableSubProducts) return null;

  return (
    <div
      className={cx({
        'choose_product': !newVersion,
        'flex flex-col gap-y-6 lg:gap-y-8': newVersion,
      })}
    >
      {availableSubProducts.map((subProductName) => renderProductCard(subProductName))}
    </div>
  );
};

export default WarriorSubProductsList;
