import React, { ComponentProps, useMemo, useState } from 'react';
import * as selectors from 'app/selectors/customer';
import { P } from 'app/components/common/Typography';
import { push } from 'connected-react-router/immutable';
import * as actions from 'app/actions/customer';
import { useAppSelector } from 'app/helpers/hooks';
import { AvailableProducts } from 'app/constants/Products';
import WhyCancelModal from 'app/components/customer/dashboard/WhyCancelModal';
import SetProductDialog from '@setproduct-ui/core/Dialog';
import { PrimaryButton, SecondaryButton } from 'app/components/common/Button';
import WhiteButton from 'app/components/common/WhiteButton';
import InfoIcon from 'images/INFO.svg';
import * as Routes from 'app/constants/Routes';
import cx from 'classnames';
import dayjs from 'dayjs';
import '../css/ManageSubscriptions.scss';
import { selectCustomerStripeInfo } from 'app/selectors/admin';
import protocolNames from 'app/utils/protocolNames';
import { useDispatch } from 'react-redux';
import CouponCodeInput from 'app/components/customer/steps/Payment/CouponCodeInput';
import { Formik } from 'formik';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import { Badge, Button, Card, PageWrapper } from 'mui';
import DiscountBanner from 'app/components/customer/Registration/KingRegistration/DiscountBanner';
import PauseSubscription from 'app/components/customer/dashboard/PauseSubscription';
import { FullscreenModal } from 'app/components/common/FullscreenModal';

const CouponItem = ({ key, discount }) => {
  const isLab = discount['product_name']?.slice(-4) === '_lab';
  const appliedFor = isLab ? 'labs' : 'subscription';
  const hideCouponCode = ['ny10pofft', 'ny15pofft', 'ny25pofft'].includes(discount['coupon']['code']);

  return (
    <div key={key} className="mt24">
      <div>
        {hideCouponCode ? '' : `“${discount['coupon']['code']}” will be applied for ${appliedFor}.`}
        <br />
        {discount['coupon']['name']}
      </div>
    </div>
  );
};

const DiscountItems = ({ products, productName }) => {
  const discounts = products[productName]['discounts'] || [];

  const newVersion = useRedesign();

  return (
    <>
      {discounts.length > 0 && (
        <>
          <div className={cx({ manage_subscription__columns: !newVersion })}>
            <div className="manage_subscription__column">
              <P className="bold">Discounts</P>
              <div className="discount-coupons-list">
                {discounts.map((discount) => (
                  <CouponItem key={discount['id']} discount={discount} />
                ))}
              </div>
            </div>
          </div>
          (!newVersion) && <div className="manage_subscription__content__separator" />)
        </>
      )}

      <div className={cx({ manage_subscription__columns: !newVersion })}>
        <div className="manage_subscription__column">
          <Formik initialValues={{}} onSubmit={() => {}}>
            <CouponCodeInput productName={productName} appliedDiscounts={discounts.length} />
          </Formik>
        </div>
      </div>

      {!newVersion && <div className="manage_subscription__content__separator" />}
    </>
  );
};

const ManageSubscriptions = () => {
  const newVersion = useRedesign();
  const dispatch = useDispatch();
  const customerId = useAppSelector(selectors.selectCustomerId);
  const productsImm = useAppSelector(selectors.selectCustomerProducts);
  const customer = useAppSelector(selectors.selectCustomer);

  const [openCancel, setOpenCancel] = useState(false);
  const [openCancelReasons, setOpenCancelReasons] = useState(false);
  const [openCancelSuccess, setOpenCancelSuccess] = useState(false);
  const [productForCancel, setProductForCancel] = useState<null | string>(null);

  const [pauseSubscriptionModal, setPauseSubscriptionModal] = useState(false);
  const [productForPause, setProductForPause] = useState<null | string>(null);

  const stripeInfo = useAppSelector((state) => selectCustomerStripeInfo(state, customerId));
  const nextBillingCycle = (stripeProductId: string) => {
    const subscription = (stripeInfo?.get('subscriptions')?.toJS() || []).find((s) => s.id === stripeProductId);
    if (!subscription) {
      return '';
    }

    return dayjs(subscription.current_period_end * 1000).format('MMMM D, YYYY');
  };
  const products = useMemo(() => productsImm.toJS(), [productsImm]);
  const activeProductWithSupplementName = customer.get('active_product_with_supplement_name');

  const reactivate = (product_name: AvailableProducts) => {
    const supplementsOnAnotherIntake =
      activeProductWithSupplementName && activeProductWithSupplementName != product_name;

    if (supplementsOnAnotherIntake && productsImm?.get(product_name)?.get('opt_in_choice')?.get('include_supplement')) {
      handleUpdateClick(product_name);
    } else {
      const uncancelParams = {
        type: 'uncancel_account',
        user_id: customerId,
        params: { product_name },
      };
      const uncancelAction = actions.apiRequestCommand({
        params: uncancelParams,
        context: {},
      });
      dispatch(uncancelAction);
    }
  };

  const openPauseModal = (product: string) => {
    setProductForPause(product);
    setPauseSubscriptionModal(true);
  };

  const openCancelModal = (product: string) => {
    setProductForCancel(product);
    if (product === AvailableProducts.King) {
      setOpenCancelReasons(true);
    } else {
      setOpenCancel(true);
    }
  };

  const handleCancelClick = () => {
    setOpenCancel(false);
    setOpenCancelReasons(true);
  };

  const backToSettings = () => {
    dispatch(push(Routes.Settings));
  };

  const handleUpdateClick = (productName: string) => {
    dispatch(push(Routes.UpdateSubscriptionForProduct(productName)));
  };

  const ModalBody = () =>
    newVersion ? (
      productForCancel ? (
        <>
          <h6>The {protocolNames[productForCancel]} Protocol</h6>
          <div className="flex flex-row gap-x-6 items-center">
            <img src={InfoIcon} />
            <p>
              Are you sure you want to cancel
              <br />
              the {protocolNames[productForCancel]} Protocol subscription?
            </p>
          </div>
          <hr />
          <Button className="dont" onClick={() => setOpenCancel(false)}>
            Don't Cancel
          </Button>
          <Button variant="text" onClick={handleCancelClick}>
            Proceed to cancel
          </Button>
        </>
      ) : (
        <></>
      )
    ) : (
      <>
        {productForCancel ? (
          <div className={`manage_subscription__cancel_modal__content ${productForCancel}`}>
            <h2>
              The <span>{protocolNames[productForCancel]} Protocol</span>
            </h2>
            <img src={InfoIcon} />
            <p>
              Are you sure you want to cancel
              <br />
              the {protocolNames[productForCancel]} Protocol subscription?
            </p>
          </div>
        ) : (
          <></>
        )}
        <div className="flex manage_subscription__cancel_modal__buttons">
          <WhiteButton className="dont" onClick={() => setOpenCancel(false)} text="Don't Cancel" />
          <WhiteButton onClick={handleCancelClick} text="Proceed to cancel" />
        </div>
      </>
    );

  const ModalBodySuccess = () => (
    <>
      <div className="manage_subscription__cancel_modal__content cancel-success">
        <p>You can un-cancel anytime by visiting the Manage Subscription section of the Settings Page</p>
      </div>
      <div className="flex manage_subscription__cancel_modal__buttons">
        <PrimaryButton text="BACK TO DASHBOARD" onClick={() => dispatch(push(Routes.Dashboard))} />
      </div>
    </>
  );

  const getNextBillingCycle = (productName) => {
    if (customer.get('spreedly_enabled')) {
      return products[productName]?.current_subscription?.next_billing_date_str;
    } else {
      return nextBillingCycle(products[productName].current_subscription?.stripe_internal_id);
    }
  };

  const getPauseSubscriptionDate = (product, field) => {
    const resumedAt = customer?.get('maximus_products')?.get(product)?.get('resume_at');

    if (resumedAt && dayjs(resumedAt).isAfter(dayjs())) {
      return customer?.get('maximus_products')?.get(product)?.get(field);
    }
  };

  const current_status = (product) => {
    const pausedAt = getPauseSubscriptionDate(product, 'pause_at');

    if (products[product].cancelled_at) {
      return 'Canceled';
    } else if (pausedAt) {
      return `Pauses on ${dayjs(pausedAt).format('MMMM D, YYYY')}`;
    } else {
      return 'Active';
    }
  };

  return newVersion ? (
    <PageWrapper wrapperClassName="flex flex-col gap-y-6 lg:gap-y-8">
      <h3 className="ml-10 sm:ml-5">Manage Subscriptions</h3>
      {Object.keys(products).map((productName) => (
        <>
          {products[productName]?.payment_successful && (
            <Card key={productName}>
              <Card.Body>
                {productName === AvailableProducts.King && <DiscountBanner />}
                <h4>{protocolNames[productName]} protocol</h4>
                <div className="flex flex-col gap-y-2">
                  <h5>Subscription status</h5>
                  <Badge variant={products[productName].cancelled_at ? 'danger' : 'success'}>
                    {products[productName].cancelled_at ? 'Canceled' : 'Active'}
                  </Badge>
                </div>
                <div className="flex flex-col gap-y-2">
                  <h5>Current subscription</h5>
                  <p>{products[productName].current_subscription?.friendly_name}</p>
                </div>
                {products[productName].current_subscription?.friendly_name !==
                  products[productName].pending_subscription?.friendly_name &&
                  !!products[productName].intakes[products[productName].intakes.length - 1]?.billing_step_completed && (
                    <div className="flex flex-col gap-y-2">
                      <h5>Pending subscription</h5>
                      <p>{products[productName].pending_subscription?.friendly_name}</p>
                      {products[productName].opt_in_choice.supplement_preorder &&
                        products[productName].opt_in_choice.include_supplement && (
                          <P className="mt8">
                            * Your request to add Building Blocks to your subscription has been successfully received.
                            When Building Blocks is back in stock your subscription will update automatically and you
                            will begin to receive Building Blocks with future orders.
                          </P>
                        )}
                    </div>
                  )}
                <div className="flex flex-col gap-y-2">
                  <h5>Next billing cycle</h5>
                  <p>
                    {customer.get('spreedly_enabled') &&
                      products[productName].current_subscription?.next_billing_date_str}
                    {!customer.get('spreedly_enabled') &&
                      nextBillingCycle(products[productName].current_subscription?.stripe_internal_id)}
                  </p>
                </div>

                <DiscountItems products={products} productName={productName} />

                {!!products[productName].cancelled_at && (
                  <Button onClick={() => reactivate(productName as AvailableProducts)}>Restart Subscription</Button>
                )}
                <Button onClick={() => handleUpdateClick(productName)}>Change Subscription</Button>
                <Button onClick={() => openCancelModal(productName)} variant="outline">
                  Cancel Subscription
                </Button>

                <div className="bg-main-grey rounded-xl p-4 lg:p-8">
                  {!products[productName].cancelled && (
                    <span>Note: Updates to your subscription will go into effect on your next billing cycle.</span>
                  )}
                  {products[productName].cancelled && (
                    <span>
                      Your subscription is canceled. You will not be charged on your next billing cycle unless you
                      choose to reactivate your subscription. If you reactivate before the billing cycle, you will keep
                      your same billing cycle. If you choose to reactivate your account after the billing cycle, a new
                      billing cycle will be set based on the date you reactivate.
                    </span>
                  )}
                </div>

                {openCancelReasons && (
                  <WhyCancelModal
                    productName={productForCancel as ComponentProps<typeof WhyCancelModal>['productName']}
                    openCancelReasons={openCancelReasons}
                    setOpenCancelReasons={setOpenCancelReasons}
                    setOpenCancelSuccess={setOpenCancelSuccess}
                  />
                )}

                {productForCancel && (
                  <SetProductDialog
                    isOpen={openCancelSuccess}
                    title={`Your ${protocolNames[productForCancel]} Protocol has been canceled`}
                    text={<ModalBodySuccess />}
                    onClose={() => setOpenCancelSuccess(false)}
                    className="manage_subscription__cancel_modal text-left"
                  />
                )}

                <SetProductDialog
                  isOpen={openCancel}
                  title="Cancel Subscription"
                  text={<ModalBody />}
                  onClose={() => setOpenCancel(false)}
                  className="manage_subscription__cancel_modal"
                />
              </Card.Body>
            </Card>
          )}
        </>
      ))}
    </PageWrapper>
  ) : (
    <div className="centered manage_subscription manage_subscription_box flex-column pt52 pb52">
      {Object.keys(products).map((productName) => (
        <React.Fragment key={productName}>
          {productName === AvailableProducts.King && <DiscountBanner />}
          {products[productName]?.payment_successful && (
            <div className={`manage_subscription__item manage_subscription__item_${productName}`}>
              <h2 className="manage_subscription__title">{protocolNames[productName]} Protocol</h2>
              <div className="mt20 manage_subscription__content">
                <div className="manage_subscription__columns">
                  <div className="manage_subscription__column">
                    <div>
                      <P className="bold">Subscription Status</P>
                      <div className="manage_subscription__content__status">
                        <div
                          className={cx('manage_subscription__content__status_circle', {
                            cancelled: !!products[productName].cancelled_at,
                            paused: !!getPauseSubscriptionDate(productName, 'pause_at'),
                          })}
                        />
                        <div>{current_status(productName)}</div>
                      </div>
                    </div>
                    <div className="mt20">
                      <P className="bold">Current Subscription</P>
                      <div className="manage_subscription__content__friendly_name mt8">
                        {products[productName].current_subscription?.friendly_name}
                      </div>
                    </div>
                    {products[productName].current_subscription?.friendly_name !==
                      products[productName].pending_subscription?.friendly_name &&
                      !!products[productName].intakes[products[productName].intakes.length - 1]
                        ?.billing_step_completed && (
                        <>
                          <div className="mt20">
                            <P className="bold">Pending Subscription</P>
                            <div className="manage_subscription__content__friendly_name mt8">
                              {products[productName].pending_subscription?.friendly_name}
                            </div>
                          </div>
                          {products[productName].opt_in_choice.supplement_preorder &&
                            products[productName].opt_in_choice.include_supplement && (
                              <P className="mt8">
                                * Your request to add Building Blocks to your subscription has been successfully
                                received. When Building Blocks is back in stock your subscription will update
                                automatically and you will begin to receive Building Blocks with future orders.
                              </P>
                            )}
                        </>
                      )}
                    <div className="mt20">
                      <P className="bold">Next Billing Cycle</P>
                      <div className="manage_subscription__content__friendly_name mt8">
                        {getNextBillingCycle(productName)}
                      </div>
                    </div>
                  </div>

                  <div className="manage_subscription__column manage_subscription__column_btns">
                    <PrimaryButton
                      className="full"
                      onClick={() => handleUpdateClick(productName)}
                      text="Change Subscription"
                    />

                    {!products[productName].cancelled_at && (
                      <SecondaryButton
                        className="full"
                        onClick={() => openCancelModal(productName)}
                        text="Cancel Subscription"
                      />
                    )}

                    {!!products[productName].cancelled_at && (
                      <SecondaryButton
                        className="full reactivate"
                        onClick={() => reactivate(productName as AvailableProducts)}
                        text="Activate Subscription"
                      />
                    )}

                    {!products[productName].cancelled && (
                      <SecondaryButton
                        className="full"
                        onClick={() => openPauseModal(productName)}
                        text={
                          getPauseSubscriptionDate(productName, 'resume_at') ? 'Change Pause' : 'Pause Subscription'
                        }
                      />
                    )}
                  </div>
                </div>

                <div className="manage_subscription__content__separator" />

                <DiscountItems products={products} productName={productName} />

                <P className="manage_subscription__info">
                  {!products[productName].cancelled && (
                    <span>Note: Updates to your subscription will go into effect on your next billing cycle.</span>
                  )}
                  {products[productName].cancelled && (
                    <span>
                      Your subscription is canceled. You will not be charged on your next billing cycle unless you
                      choose to reactivate your subscription. If you reactivate before the billing cycle, you will keep
                      your same billing cycle. If you choose to reactivate your account after the billing cycle, a new
                      billing cycle will be set based on the date you reactivate.
                    </span>
                  )}
                </P>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
      <PrimaryButton text="BACK TO SETTINGS" onClick={backToSettings} className="manage_subscription__back" />
      {openCancelReasons && (
        <WhyCancelModal
          productName={productForCancel as ComponentProps<typeof WhyCancelModal>['productName']}
          openCancelReasons={openCancelReasons}
          setOpenCancelReasons={setOpenCancelReasons}
          setOpenCancelSuccess={setOpenCancelSuccess}
        />
      )}
      {productForCancel && (
        <SetProductDialog
          isOpen={openCancelSuccess}
          title={`Your ${protocolNames[productForCancel]} Protocol has been canceled`}
          text={<ModalBodySuccess />}
          onClose={() => setOpenCancelSuccess(false)}
          className="manage_subscription__cancel_modal text-left"
        />
      )}
      <SetProductDialog
        isOpen={openCancel}
        title="Cancel Subscription"
        text={<ModalBody />}
        onClose={() => setOpenCancel(false)}
        className="manage_subscription__cancel_modal"
      />
      {pauseSubscriptionModal && productForPause && (
        <FullscreenModal
          isOpen={pauseSubscriptionModal}
          onClose={() => setPauseSubscriptionModal(false)}
          text={
            <PauseSubscription
              setPauseSubscriptionModal={setPauseSubscriptionModal}
              nextBillingCycle={getNextBillingCycle(productForPause)}
              product={productForPause}
              subProduct={products[productForPause]?.opt_in_choice?.selected_king_v2_product}
              resumeAt={getPauseSubscriptionDate(productForPause, 'resume_at')}
              pausedAt={getPauseSubscriptionDate(productForPause, 'pause_at')}
              paused={products[productForPause].paused}
            />
          }
        />
      )}
    </div>
  );
};

export default ManageSubscriptions;
