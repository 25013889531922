import PriceFormatter from 'app/components/customer/steps/Payment/Generic/PriceFormatter';
import React, { useContext } from 'react';
import { useFormikContext } from 'formik';
import TitleWithLine from 'app/components/common/Typography/TitleWithLine';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import Divider from '@setproduct-ui/core/Divider';
import PriceCalculator from 'app/helpers/priceCalculator';
import CouponCodeInput from '../CouponCodeInput';
import DiscountCouponsList from '../DiscountCouponsList';
import { AvailableProducts, LabKitTypes, KingV2SubProducts } from 'app/constants/Products';
import { P } from 'app/components/common/Typography';
import { useAppSelector } from 'app/helpers/hooks';
import { selectCurrentIntakeProduct } from 'app/selectors/customer';
import * as selectors from 'app/selectors/customer';
import { ProductContext } from 'app/components/customer/Product';
import { useExperiment } from 'app/utils/useExperiment';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';

type Props = {
  title?: string;
  multimonthPeriod: string;
  isOnboarding: boolean;
  showLabKit: boolean;
  priceCalculator: PriceCalculator;
  intake: any;
};

const GenericDueToday = ({ title, isOnboarding, priceCalculator, multimonthPeriod, showLabKit, intake }: Props) => {
  const { values } = useFormikContext<{
    use_own_lab: boolean;
    lab_kit_type: string;
  }>();
  const { use_own_lab, lab_kit_type } = values;

  const productCtx = useContext(ProductContext);
  const product = useAppSelector((state) => selectors.selectCustomerProduct(state, productCtx?.selectedProduct));
  const currentProductName = useAppSelector(selectCurrentIntakeProduct);

  const labsFreeExperiment =
    useExperiment('testosterone_discount_labs_free', 'testosterone_discount_labs_free_variation_0') ===
    'testosterone_discount_labs_free_variation_1';

  const isLabsFree = isOnboarding && currentProductName === 'king' && labsFreeExperiment;

  const kingV2Product = intake?.get('selected_king_v2_product');
  const atHomeLabKitDesc = [KingV2SubProducts.EncloPregnolone, KingV2SubProducts.Enclo].includes(kingV2Product)
    ? 'Lab Kit charges apply to this month and next month only'
    : 'Lab Kit charges apply in the first three months of treatment to track baseline levels and improvement over time';

  const newVersion = useRedesign();

  return newVersion ? (
    <div className="flex flex-col gap-y-2">
      {isOnboarding && (
        <div className="flex flex-row">
          <div className="flex-1">
            Subscription <span>({multimonthPeriod.toLowerCase()})</span>
          </div>
          <div>
            <PriceFormatter size="small" price={priceCalculator.subscriptionCost()} />
          </div>
        </div>
      )}
      {!priceCalculator.freeShipping() && (
        <div className="flex flex-row">
          <div className="flex-1">Priority 2 day shipping</div>
          <div>
            <PriceFormatter size="small" price={priceCalculator.shippingPrice()} />
          </div>
        </div>
      )}
      {!use_own_lab && showLabKit && (
        <div className="flex flex-row">
          <div className="flex-1">{lab_kit_type === LabKitTypes.WalkIn ? 'Walk-in Lab' : 'At-Home Lab Kit:'}</div>
          {isLabsFree && lab_kit_type === LabKitTypes.AtHomeLabKit ? (
            <PriceFormatter size="small" price={priceCalculator.atHomeLabPrice() as number} free={isLabsFree} />
          ) : (
            <PriceFormatter size="small" price={priceCalculator.labCost()} />
          )}
        </div>
      )}
      {priceCalculator.appointmentCost() > 0 && (
        <div className="flex flex-row">
          <div className="flex-1">Video Consultation</div>
          <div>
            <PriceFormatter size="small" price={priceCalculator.appointmentCost()} showCents={true} />
          </div>
        </div>
      )}
      <div className="flex flex-row">
        <div className="flex-1">Priority Shipping (to you)</div>
        <div>Free</div>
      </div>
      <div className="flex flex-row">
        <div className="flex-1">Overnight Shipping (to the lab)</div>
        <div>Free</div>
      </div>

      <Divider className="my-3" />

      <div className="flex flex-row">
        <h5 className="flex-1">TOTAL:</h5>
        <PriceFormatter price={priceCalculator.totalDueToday()} showCents={true} />
      </div>
      <div className="discount-coupons-container">
        <DiscountCouponsList priceCalculator={priceCalculator} />
        <div className="price_row">
          <CouponCodeInput
            productName={intake?.get('product_name')}
            appliedDiscounts={product?.get('discounts')?.toArray()?.length}
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      <TitleWithLine className="mt52">{title ? title : 'Due Today'}</TitleWithLine>
      <PaymentCard>
        {isOnboarding && (
          <div className="price_row mb24">
            <div>
              Subscription{' '}
              <span className="generic_payment_page__multi_month_variant">({multimonthPeriod.toLowerCase()})</span>
            </div>
            <div>
              <PriceFormatter price={priceCalculator.subscriptionCost()} />
            </div>
          </div>
        )}
        {!priceCalculator.freeShipping() && (
          <div className="price_row mt24">
            <div>Priority 2 day shipping</div>
            <div>
              <PriceFormatter price={priceCalculator.shippingPrice()} />
            </div>
          </div>
        )}
        {!use_own_lab && showLabKit && (
          <>
            <div className="price_row mt24">
              <div>{lab_kit_type === LabKitTypes.WalkIn ? 'Walk-in Lab' : 'At-Home Lab Kit:'}</div>
              {isLabsFree && lab_kit_type === LabKitTypes.AtHomeLabKit ? (
                <PriceFormatter price={priceCalculator.atHomeLabPrice() as number} free={isLabsFree} />
              ) : (
                <PriceFormatter price={priceCalculator.labCost()} />
              )}
            </div>
            {currentProductName === AvailableProducts.King && !isLabsFree && (
              <P className="lab_step_paragraph_format">
                {lab_kit_type === LabKitTypes.WalkIn
                  ? 'Lab Kit charges apply to this month and the next 2 months only'
                  : isLabsFree
                    ? ''
                    : atHomeLabKitDesc}
              </P>
            )}
          </>
        )}
        {priceCalculator.appointmentCost() > 0 && (
          <>
            <div className="price_row mt24">
              <div>Video Consultation</div>
              <div>
                <PriceFormatter price={priceCalculator.appointmentCost()} showCents={true} />
              </div>
            </div>
            <div className="due_today_description">
              A video consult with your Maximus doctor is required before beginning treatment. Instructions for
              scheduling to follow.
            </div>
          </>
        )}

        <div className="discount-coupons-container">
          {!isLabsFree && <h4 className="subtitle">Coupons</h4>}
          <DiscountCouponsList priceCalculator={priceCalculator} />
          <div className="price_row">
            <CouponCodeInput
              productName={intake?.get('product_name')}
              appliedDiscounts={product?.get('discounts')?.toArray()?.length}
            />
          </div>
        </div>

        <Divider className="mt32 mb32" />
        <div className="price_row">
          <div>TOTAL:</div>
          <PriceFormatter price={priceCalculator.totalDueToday()} showCents={true} />
        </div>
      </PaymentCard>
    </>
  );
};

export default GenericDueToday;
