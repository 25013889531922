import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Map } from 'immutable';
import { Formik, FormikProps, Form, FormikErrors } from 'formik';
import cx from 'classnames';
import { useAppSelector } from 'app/helpers/hooks';
import { ImmutableMap } from 'app/types/admin';
import { RootState } from 'app/configureStore';
import { CustomerShippingAddress } from 'app/types';
import Input, { InputMask } from 'app/components/common/formik/Input';
import Spinner from 'app/components/customer/Spinner';
import { Dropdown as BlackDropdown } from 'app/components/common/formik/Dropdown';
import * as selectors from 'app/selectors/customer';
import { clearAddressFormErrors } from 'app/actions/customer';
import { zipCodeDisplayFormatter, ZIP_CODE_MASK, PHONE_MASK, phoneDisplayFormatter } from 'app/helpers/formatters';
import { ShippingSchema } from 'app/helpers/validators';
import PaymentCard from 'app/components/customer/steps/Payment/PaymentCard';
import OutOfServiceAlert from 'app/components/customer/steps/Payment/OutOfServiceAlert';
import '../../css/ShippingAddress.scss';
import { Checkbox } from 'app/components/common/formik/Checkbox';
import { P } from 'app/components/common/Typography';
import { apiRequestPromiseViaDispatch } from 'app/api';
import axios from 'axios';
import ProductNotAvailableAlert from 'app/components/customer/steps/Payment/ProductNotAvailableAlert';
import { selectCurrentIntake } from 'app/selectors/customer';
import { BaseIntake, MaximusProduct } from 'app/types/admin/customerUser';
import { useRedesign } from 'app/utils/redesign/RedesignProvider';
import FormInput from 'app/components/common/formik/FormInput';
import { APIProvider, useMap, useMapsLibrary, Map as GMap } from '@vis.gl/react-google-maps';
import GoogleLogo from 'images/google-logo/google_on_white_hdpi.png';

export interface ShippingValues extends CustomerShippingAddress {
  phone_number: string;
  receive_sms: boolean;
}

export interface ParsedAddressObject {
  street?: string;
  streetNumber?: number;
  city?: string;
  zip?: number;
  state?: string;
}

const mapStateToProps = (reduxState: RootState) => {
  const shippingAddress = selectors.selectCustomerShippingAddress(reduxState) || Map();
  const customer = selectors.selectCustomer(reduxState);
  const phone_number = customer.get('phone_number') || '';
  const receive_sms = !!customer.get('receive_sms');
  const { city = '', address_line_1 = '', address_line_2 = '', state = '', postal_code = '' } = shippingAddress.toJS();
  const initialFullName = selectors.selectCustomerFullName(reduxState);
  const initialValues = shippingAddress.merge({
    full_name: initialFullName,
    address_line_1,
    address_line_2,
    city,
    receive_sms,
    state,
    postal_code,
    phone_number: phoneDisplayFormatter(phone_number),
  });
  const initialErrors = reduxState.customer.getIn(['forms', 'update_address_form', 'errors'], {});

  return {
    initialErrors,
    initialValues,
  };
};
export const ShippingFields: FC<{
  disabled?: boolean;
  labels?: boolean;
  className?: string;
  inputDefaultClassName?: string;
  handleStateSelection: (option: string) => void;
}> = ({ disabled, className, labels = true, inputDefaultClassName = 'mt12', handleStateSelection }) => {
  const newVersion = useRedesign();

  return newVersion ? (
    <div>
      <div className="mb-4">
        <FormInput id="address_line_1" label="Street address" placeholder="Street 1" name="address_line_1" />
        <FormInput id="address_line_2" placeholder="Street 2" name="address_line_2" />
      </div>
      <div className="mb-4">
        <FormInput label="City" placeholder="City" name="city" />
      </div>
      <div className="flex flex-row align-justify gap-2">
        <div className="flex1">
          <BlackDropdown
            id="state"
            name="state"
            disabled={disabled}
            className={className}
            emptyValue="State"
            showLabel={labels}
            onStateChange={handleStateSelection}
          />
        </div>
        <div className="flex1">
          <InputMask
            id="postal_code"
            name="postal_code"
            label={labels ? 'ZIP' : null}
            displayFormatter={zipCodeDisplayFormatter}
            mask={ZIP_CODE_MASK}
            inputMode="numeric"
            placeholder="00000"
            className={className}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className={inputDefaultClassName}>
        <Input
          id="address_line_1"
          name="address_line_1"
          label={labels ? 'Street Line 1' : null}
          placeholder="Street 1"
          disabled={disabled}
          className={className}
        />
      </div>
      <div className={inputDefaultClassName}>
        <Input
          id="address_line_2"
          name="address_line_2"
          label={labels ? 'Street Line 2' : null}
          placeholder="Street 2"
          disabled={disabled}
          className={className}
        />
      </div>
      <div className={cx(inputDefaultClassName, 'flex')}>
        <div className="flex1">
          <Input
            id="city"
            name="city"
            label={labels ? 'City' : null}
            placeholder="City"
            className={className}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={inputDefaultClassName} />
      <div className={cx(inputDefaultClassName, 'flex')}>
        <div className="flex1">
          <BlackDropdown
            id="state"
            name="state"
            disabled={disabled}
            className={className}
            showLabel={labels}
            onStateChange={handleStateSelection}
          />
        </div>
        <div className="flex1">
          <InputMask
            id="postal_code"
            name="postal_code"
            label={labels ? 'ZIP' : null}
            displayFormatter={zipCodeDisplayFormatter}
            mask={ZIP_CODE_MASK}
            inputMode="numeric"
            className={className}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};

// For the why: Google Maps need to live outside or useMap hook won't work.
// This needs to live as a set if you want autocomplete work.
export const ShippingFieldsWithAutocompleteWrapper: FC<{
  values: ShippingValues;
  setFieldValue: (
    field: string,
    value: React.SetStateAction<any>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<ShippingValues>>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<ShippingValues>>;
  handleStateSelection: (option: string) => void;
}> = ({ values, setFieldValue, handleStateSelection, setFieldTouched }) => {
  return (
    <APIProvider apiKey={window.googleMapsAPIKey}>
      <ShippingFieldsWithAutocomplete
        values={values}
        setFieldValue={setFieldValue}
        handleStateSelection={handleStateSelection}
        setFieldTouched={setFieldTouched}
      />
      <GMap />
    </APIProvider>
  );
};

//We'll delete the other once we launch this.
export const ShippingFieldsWithAutocomplete: FC<{
  disabled?: boolean;
  labels?: boolean;
  className?: string;
  inputDefaultClassName?: string;
  values?: ShippingValues;
  setFieldValue?: (
    field: string,
    value: React.SetStateAction<any>,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<ShippingValues>>;
  setFieldTouched?: (
    field: string,
    isTouched?: boolean,
    shouldValidate?: boolean,
  ) => Promise<void | FormikErrors<ShippingValues>>;
  handleStateSelection: (option: string) => void;
}> = ({
  disabled,
  className,
  values,
  labels = true,
  inputDefaultClassName = 'mt12',
  handleStateSelection,
  setFieldValue,
  setFieldTouched,
}) => {
  const [sessionToken, setSessionToken] = useState<google.maps.places.AutocompleteSessionToken>();
  const [autocompleteService, setAutoCompletservice] = useState<google.maps.places.AutocompleteService | null>(null);
  const [placesService, setPlacesService] = useState<google.maps.places.PlacesService | null>(null);
  const [predictions, setPredictions] = useState<Array<google.maps.places.AutocompletePrediction>>([]);
  const [lockPredictions, setLockPredictions] = useState<boolean>(false);
  const places = useMapsLibrary('places');
  const map = useMap();
  const wrapperRef = useRef(null);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPredictions([]);
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };

  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (!places || !map) return;

    setAutoCompletservice(new places.AutocompleteService());
    setSessionToken(new places.AutocompleteSessionToken());
    setPlacesService(new places.PlacesService(map));

    return () => setAutoCompletservice(null);
  }, [places, map]);

  useEffect(() => {
    if (!values || lockPredictions) return;
    const street1 = values.address_line_1;
    fetchPrediction(street1);
  }, [places, values]);

  const fetchPrediction = useCallback(
    async (input) => {
      if (!autocompleteService || !input) {
        setPredictions([]);
        return;
      }
      const request = {
        input: input,
        componentRestrictions: { country: 'us' },
        sessionToken,
      };
      const response = await autocompleteService.getPlacePredictions(request);

      setPredictions(response.predictions);
    },
    [values, sessionToken],
  );

  const handlePrediction = (placeId: string) => {
    if (!places || !setFieldValue || !setFieldTouched) return;

    const detailRequestOptions = {
      placeId,
      fields: ['address_components'],
      sessionToken,
    };

    const detailRequestCallback = (placeDetails: google.maps.places.PlaceResult | null) => {
      const parsedAddressObject: ParsedAddressObject = parseAddressComponent(placeDetails?.address_components || []);
      const { street, streetNumber, city, zip, state } = parsedAddressObject;
      if (street && streetNumber) {
        setFieldValue('address_line_1', `${parsedAddressObject.streetNumber} ${parsedAddressObject.street}`);
        setFieldTouched('address_line_1');
      }
      if (city) {
        setFieldValue('city', city);
        setFieldTouched('city');
      }
      if (zip) {
        setFieldValue('postal_code', zip);
        setFieldTouched('postal_code');
      }
      if (state) {
        setFieldValue('state', state);
      }
      setPredictions([]);
      setLockPredictions(false);
    };

    setLockPredictions(true);
    placesService?.getDetails(detailRequestOptions, detailRequestCallback);
  };

  const parseAddressComponent = (addressComponent: any[]) => {
    const result = {};

    for (const component of addressComponent) {
      const type = component.types;
      let key: string | undefined;

      if (type.includes('street_number')) {
        key = 'streetNumber';
      } else if (type.includes('route')) {
        key = 'street';
      } else if (type.includes('locality')) {
        key = 'city';
      } else if (type.includes('administrative_area_level_1')) {
        key = 'state';
      } else if (type.includes('postal_code')) {
        key = 'zip';
      }

      if (key) result[key] = component.short_name;
    }

    return result;
  };

  const renderPredictions = () => {
    if (!predictions.length) return null;

    return (
      <ul className="google_autocomplete_container" ref={wrapperRef}>
        {predictions.map(({ place_id, description }) => {
          return (
            <React.Fragment key={`${place_id}_wrapper`}>
              <li className="input" key={place_id} onClick={() => handlePrediction(place_id)}>
                {description}
              </li>
              <hr />
            </React.Fragment>
          );
        })}
        <div className="google-logo-section" key="google-logo-section">
          Powered by <img src={GoogleLogo} />
        </div>
      </ul>
    );
  };

  return (
    <>
      <div className={inputDefaultClassName}>
        <Input
          id="address_line_1"
          name="address_line_1"
          label={labels ? 'Street Line 1' : null}
          placeholder="Street 1"
          disabled={disabled}
          className={className}
        />
      </div>
      {renderPredictions()}
      <div className={inputDefaultClassName}>
        <Input
          id="address_line_2"
          name="address_line_2"
          label={labels ? 'Street Line 2' : null}
          placeholder="Street 2"
          disabled={disabled}
          className={className}
        />
      </div>
      <div className={cx(inputDefaultClassName, 'flex')}>
        <div className="flex1">
          <Input
            id="city"
            name="city"
            label={labels ? 'City' : null}
            placeholder="City"
            className={className}
            disabled={disabled}
          />
        </div>
      </div>
      <div className={inputDefaultClassName} />
      <div className={cx(inputDefaultClassName, 'flex')}>
        <div className="flex1">
          <BlackDropdown
            id="state"
            name="state"
            disabled={disabled}
            className={className}
            showLabel={labels}
            onStateChange={handleStateSelection}
          />
        </div>
        <div className="flex1">
          <InputMask
            id="postal_code"
            name="postal_code"
            label={labels ? 'ZIP' : null}
            displayFormatter={zipCodeDisplayFormatter}
            mask={ZIP_CODE_MASK}
            inputMode="numeric"
            className={className}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};

export interface IShippingStep {
  initialValues: ImmutableMap<ShippingValues>;
  initialErrors: Record<keyof ShippingValues, string>;
  shippingFormRef: any;
  productPharmacyValid: boolean;
  titleClasses?: string;
  setProductPharmacyValid: (submitted: boolean) => void;
  setShippingFormValid: (submitted: boolean) => void;
  productFromParam?: ImmutableMap<MaximusProduct>;
  intakeFromParam?: ImmutableMap<BaseIntake>;
  currentProductName?: string;
}
const GenericShippingForm = ({
  initialValues,
  initialErrors,
  shippingFormRef,
  productPharmacyValid,
  productFromParam,
  intakeFromParam,
  setProductPharmacyValid,
  setShippingFormValid,
  titleClasses = 'title_with_line xl mt48 mv16',
  currentProductName,
}: IShippingStep) => {
  const product =
    productFromParam ||
    useAppSelector((state) => selectors.selectCustomerProduct(state, selectors.selectCurrentIntakeProduct(state)));
  const pharmacyProductName = product?.get('pharmacy_product_name');
  const validStates = useAppSelector(selectors.selectValidStates);
  const customerId = useAppSelector(selectors.selectCustomerId);
  const intake = intakeFromParam || useAppSelector(selectCurrentIntake);
  const intakeName = intake?.get('name');
  const dispatch = useDispatch();

  const onSubmit = async (values: ShippingValues, form) =>
    apiRequestPromiseViaDispatch({
      dispatchFn: dispatch,
      path: '/api/commands',
      body: {
        type: 'update_shipping_address',
        user_id: customerId as string,
        params: {
          ...values,
          phone_number: values.phone_number.replace(/\D/g, ''),
          intake: intakeName,
        },
      },
      onErrorFn: (errors) => {
        Object.entries(errors?.parsedJson?.errors).forEach((entry) => {
          const [key, value] = entry;
          form.setFieldError(key, value as string);
        });
      },
      form,
    });

  const handleStateSelection = async (option: string) => {
    const response = await axios.get('/api/product_pharmacy/product_pharmacy_availability', {
      params: {
        pharmacy_product_name: pharmacyProductName,
        state: option,
      },
    });
    const { product_pharmacy_available } = response.data;
    setProductPharmacyValid(!!product_pharmacy_available && validStates.includes(option));
  };

  const newVersion = useRedesign();

  return newVersion ? (
    <Formik
      initialValues={initialValues.toJS()}
      validationSchema={ShippingSchema}
      onSubmit={onSubmit}
      innerRef={shippingFormRef}
      validateOnMount={true}
    >
      {({ isSubmitting, values, isValid, setFieldValue }: FormikProps<ShippingValues>) => {
        setShippingFormValid(isValid);
        return (
          <Form>
            {values?.state && !validStates.includes(values.state) && <OutOfServiceAlert validStates={validStates} />}
            {values?.state && !productPharmacyValid && <ProductNotAvailableAlert />}

            {isSubmitting ? (
              <Spinner isCenter />
            ) : (
              <>
                <h5 className="mb-6 lg:mb-8">Shipping Address</h5>
                <ShippingFields handleStateSelection={handleStateSelection} />
                <div className="mb-6 mt-2">
                  <InputMask
                    id="phone_number"
                    name="phone_number"
                    onKeyUp={() => dispatch(clearAddressFormErrors())}
                    label="Phone Number"
                    displayFormatter={phoneDisplayFormatter}
                    mask={PHONE_MASK}
                    placeholder="(___) ___-___"
                    inputMode="tel"
                    initialError={initialErrors.phone_number}
                  />
                </div>
                <Checkbox
                  topAligned
                  label={
                    <P className="">
                      Allow text/SMS messages from Maximus. Message and data rates may apply. Reply STOP to opt-out.{' '}
                      <a href="/terms-of-use" target="_blank">
                        Terms and Privacy Policy
                      </a>
                    </P>
                  }
                  testId="receive_sms"
                  name="receive_sms"
                  onChange={(evt) => setFieldValue('receive_sms', evt.target.checked)}
                  checked={values.receive_sms}
                />
              </>
            )}
          </Form>
        );
      }}
    </Formik>
  ) : (
    <>
      <h2 className={`${titleClasses} animated-deep-hidden`}>Shipping Address</h2>
      <Formik
        initialValues={initialValues.toJS()}
        validationSchema={ShippingSchema}
        onSubmit={onSubmit}
        innerRef={shippingFormRef}
        validateOnMount={true}
      >
        {({ isSubmitting, values, isValid, setFieldValue, setFieldTouched }: FormikProps<ShippingValues>) => {
          setShippingFormValid(isValid);
          return (
            <Form>
              {values?.state && !validStates.includes(values.state) && <OutOfServiceAlert validStates={validStates} />}
              {values?.state && !productPharmacyValid && <ProductNotAvailableAlert />}
              <PaymentCard className="animated-deep">
                {isSubmitting ? (
                  <Spinner isCenter />
                ) : (
                  <>
                    {currentProductName === 'king' ? (
                      <ShippingFieldsWithAutocompleteWrapper
                        values={values}
                        setFieldValue={setFieldValue}
                        handleStateSelection={handleStateSelection}
                        setFieldTouched={setFieldTouched}
                      />
                    ) : (
                      <ShippingFields handleStateSelection={handleStateSelection} />
                    )}
                    <div className="mt12 mt16 mb24">
                      <InputMask
                        id="phone_number"
                        name="phone_number"
                        onKeyUp={() => dispatch(clearAddressFormErrors())}
                        label="Phone"
                        displayFormatter={phoneDisplayFormatter}
                        mask={PHONE_MASK}
                        inputMode="tel"
                        initialError={initialErrors.phone_number}
                      />
                    </div>
                    <Checkbox
                      topAligned
                      label={
                        <>
                          <P style={{ fontSize: '10px' }}>
                            I consent to receive text/SMS messages from Maximus, including important updates related to
                            my active subscriptions. I understand I can opt-out at any time by replying STOP to these
                            messages. I understand message and data rates may apply.
                          </P>
                          <P style={{ fontSize: '10px' }}>
                            Please refer to our{' '}
                            <a href="/terms-of-use" target="_blank" style={{ fontSize: '10px' }}>
                              Terms and Privacy Policy
                            </a>
                          </P>
                        </>
                      }
                      testId="receive_sms"
                      name="receive_sms"
                      onChange={(evt) => setFieldValue('receive_sms', evt.target.checked)}
                      checked={values.receive_sms}
                    />
                  </>
                )}
              </PaymentCard>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default connect(mapStateToProps)(GenericShippingForm);
